import React from 'react'
import ScienceIntroSection from './ScienceIntroSection'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader'
import ScienceSecondSection from './ScienceSecondSection'
import Featured from '../../../components/featured/Featured'
import HowSilvaDeveloped from './HowSilvaDeveloped'
import Psychorientology from './Psychorientology'
import OtherSections from './OtherSections'
import ControlBrainWavesSection from './ControlBrainWavesSection'
import SilvaMethodOverview from './ProjectSection'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import HabitControl from './HabitControl'
import OtherSections2 from './OtherSections2'
import CustomFooter from '../../Home/Home/HomeFooter/Footer'

function SciencePage() {
  return (
    <div>
      {/* <HomeHeader />
        <div style={{
            background:"#f6e58d",
            marginBottom:"20px"

        }}>
 <ScienceIntroSection />
        </div>
        <Featured />
        <ScienceSecondSection />
        <HowSilvaDeveloped />
        <Psychorientology /> */}
         <HomeHeader />
         {/* <Psychorientology /> */}
         <div className='container'>
<OtherSections />
{/* <HabitControl /> */}
         </div>
        {/* <ControlBrainWavesSection />
        <SilvaMethodOverview />
        <HabitControl />
        <OtherSections2 /> */}

      {/* <Container fluid className="p-5">
        <Row>
          <Col md={8} className="blog-content">
            <section id="section1">
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
              <h2>A Scientist Takes a Peek</h2>
              <p>
                In the beginning, José Silva was alone in his research. Even his wife, Paula, was skeptical and cautious until she eventually saw the benefits in the work he was doing with their children.
              </p>
              <img src="https://via.placeholder.com/800x400" alt="Blog content" className="img-fluid my-3" />
              <p>
                Eventually, Silva's work attracted the attention of one scientist, who became a close friend and associate, and who brought the research findings to the attention of many other scientists.
              </p>
            </section>
          </Col>

          <Col md={4}>
            <div className="sticky-sidebar">
              <Nav className="flex-column">
                <Nav.Link href="#section1">A Scientist Takes a Peek</Nav.Link>
                <Nav.Link href="#section2">A Very Successful Visit</Nav.Link>
                <Nav.Link href="#section3">Verified Results</Nav.Link>
                <Nav.Link href="#section4">New Projects Suggested</Nav.Link>
              </Nav>

              <div className="newsletter mt-5">
                <h4>Get 1% better every day</h4>
                <form>
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Name" />
                  </div>
                  <div className="mb-3">
                    <input type="email" className="form-control" placeholder="Your email" />
                  </div>
                  <button type="submit" className="btn btn-warning btn-block">Subscribe</button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <CustomFooter />
    </div>
  )
}

export default SciencePage