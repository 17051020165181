import React, { useContext, useEffect } from "react";
import HomeHeader from "./HomeHeader/HomeHeader";
import CustomFooter from "./HomeFooter/Footer";
import LoggedInHeroSection from "./Hero/LoggedInHeroSection";
import SilvaCourseCards from "./Hero/SilvaCourseCards";
import LiveEventsCard from "./Hero/LiveEventsCard";
import CompleteProfileCard from "./Hero/CompleteProfileCard";
import { AuthContext } from "../../../context/AllContext";
import { useState } from "react";
import { reqData, requestData4 } from "../../../utils/baseUrl";
import { Link, useNavigate } from "react-router-dom";
import OtherCourses from "./OtherCourses";
import QrCodeSection from "./Homestudycourses/QrCodeSection";
import { Alert, Col, Container, Image, Row } from "react-bootstrap";
import { FaWhatsapp, FaFileDownload } from "react-icons/fa";
import OnlineEventsCard from "./Hero/OnlineEventsCard";
import { MdOutlineClose } from "react-icons/md";
import ZoomDetails from "./ZoomDetails";
import CourseProgressions from "./Homestudycourses/CourseProgressions";

const SilvaHome2 = () => {
  const navigate = useNavigate();

  const { userData, isMobile, isDesktopOrLaptop, isBigScreen } =
    useContext(AuthContext);
  const [lastCourse, setLastCourse] = useState();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);


  // console.log(userData,'profile')

  useEffect(() => {
    if (userData) {
      lastViewedCourse();
    }
  }, [userData]);

  const lastViewedCourse = async () => {
    const res = await reqData("getLastVisitedLesson", "POST", {});
    if (res && res.error === false) {
      // console.log(res.data)
      setLastCourse(res.data);
    }
  };

  const handleNavigate = () => {
    navigate("/silva-life-system-with-dragan");
  };

  function arraysAreEqual(arr1, arr2) {
    // Check if every element in arr2 is included in arr1
    return arr2.every(element => arr1.includes(element));
}



  // const getAllCourses = async () => {
  //   setLoading(true);
  //   const res = await requestData4("latestCourseList", "POST", {
  //     "start_index": "0",
  //     "no_of_records": "20"
  //   });
  //   setLoading(false);
  //   const enrolledCourses = [...userData.enrolled_course].map((val)=>val.course_id);


  //   const filtered = res.data.filter((c)=>{
  //     if(c.is_combo_course==="Yes"){
  //       alert("this ran")
  //       const included = c.included_course.map((ci)=>ci.course_id);

  //       // const result = included.every((val)=>enrolledCourses.includes(val))

  //       // console.log(result,"result",included,enrolledCourses)

  //       const result = arraysAreEqual(enrolledCourses,included)
  //       return result
  //       // const result = arraysAreEqual(enrolledCourses)
  //     }else{
  //       return !enrolledCourses.includes(c.course_id)
  //     }
  //   })


  //   console.log(filtered,"filterdata")


  //   // const enrolledCoursesMapped = enrolledCourses.map((val) => val.course_id).filter((el, i) => el.course_id !== 'top-mental-training');
  //   // const filteredArr = res.data.filter((val, i) => enrolledCoursesMapped.includes(val.course_id) && val.is_combo_course === "No");
  //   // setAllCourses(filteredArr);
  // };

  // useEffect(() => {
  //   getAllCourses()
  // }, [])







  return (
    <>
      <HomeHeader />

      <Container style={{ marginTop: isDesktopOrLaptop ? "150px" : "100px" }}>
        <Row>
          <Col lg={4} md={6} sm={12} className="px-1">
            <button
              className="footer-whatapp-Btn w-100"
              style={{ padding: "10px" }}
              onClick={() => {
                const whatsappGroupURL =
                  userData?.live_class_purchased === "Yes"
                    ? "https://chat.whatsapp.com/HalehUdcSHRLkBaBuHsT6k"
                    : "https://chat.whatsapp.com/GBCoRag4mJt3CpZybbfuzA";

                window.open(whatsappGroupURL);
              }}
            >
              <span>
                <FaWhatsapp size={30} color="white" />
              </span>{" "}
              {userData?.live_class_purchased === "Yes"
                ? "Silva live interactive group"
                : "Join Home Study Group"}
            </button>
          </Col>
          <Col lg={4} md={6} sm={12} className="px-1">
            <button
              className="button-88 py-3 w-100"
              style={{ padding: "10px" }}
            >
              <a
                href="/documents/Navigation_pdf _for_user _Phone.pdf"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaFileDownload size={20} color="white" />
                </span>{" "}
                Download Naviagtion Pdf(Mobile)
              </a>
            </button>
          </Col>
          <Col lg={4} md={6} sm={12} className="px-1">
            <button
              className="button-88 py-3 w-100"
              style={{ padding: "10px" }}
            >
              <a
                href="/documents/Navigation_pdf _for_user _web.pdf"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <span>
                  <FaFileDownload size={20} color="white" />
                </span>{" "}
                Download Naviagtion Pdf(Web)
              </a>
            </button>
          </Col>
        </Row>
      </Container>
      {show && (
        <div className="container position-relative">
          <Alert variant={"warning"} dismissible>
            <span
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 5,
                right: 5,
              }}
              onClick={() => setShow(false)}
            >
              <MdOutlineClose color="#000" />
            </span>
            <Alert.Heading>Update Alert!</Alert.Heading>
            <p>
              As we experiment some new features, users now can see their course
              progress on <span className="fw-bold">course details page.</span>
            </p>
          </Alert>
        </div>
      )}
      {/* {userData?.live_class_purchased ==="Yes" && <LiveClassZoom/>} */}
      {userData?.live_class_purchased === "Yes" && <ZoomDetails />}
      <div className="mt-4">
        <LoggedInHeroSection
          text={"Hi " + userData?.first_name || "Hi ,Annonymous"}
        />

      </div>


    

      <div className="container">
        {lastCourse && lastCourse?.lesson_id && (
          <Link
            to={`/store/course/${lastCourse.course_id}/${lastCourse.chapter_id}/${lastCourse.lesson_id}`}
          >
            <button
              className="primary_btnnew"
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                fontWeight: "600",
                lineHeight: "15px",
              }}
            >
              Last viewed lesson : {lastCourse?.lesson_id?.replace(/-/g, " ")} (
              {lastCourse?.course_id?.replace(/-/g, " ")})
            </button>
          </Link>
        )}
      </div>
      <div className="my-4">
      <CourseProgressions />

      </div>

      <SilvaCourseCards />

      <OtherCourses />

      <OnlineEventsCard />

      <QrCodeSection purchased />

      {userData &&
        (!userData?.first_name ||
          !userData?.last_name ||
          !userData?.phone ||
          !userData?.email ||
          !userData?.profile_image ||
          !userData.biographical_info) && <CompleteProfileCard />}

      <CustomFooter />
    </>
  );
};

export default SilvaHome2;
