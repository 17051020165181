import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { handleScrollCombo, handleScrollCombo2 } from '../../../utils/handleScrollEmotions';

const CourseCard = ({ title, description, imageSrc, buttonText, onClick }) => (
  <div className="course-card-combo">
    <div className="card-inner">
      <div className="card-front">
        <img src={imageSrc} alt={title} className="course-image" />
        <h3 className="course-title-combo">{title}</h3>
        <p className="course-description-combo">{description}</p>
        <button className="course-button" onClick={onClick}>
          {buttonText}
        </button>
      </div>
    
    </div>
  </div>
);

const LiveCourseSection = ({scrollTo}) => {

  return (
    <div className="live-course-section">
      <div className="course-container">
        <CourseCard
          title="Silva Method Complete Home Study Program"
          description="Get access to complete Silva Method Digital course for a comprehensive learning experience."
          imageSrc="/img/combo_course_image.png"
          buttonText="View Curriculum"
          onClick={scrollTo?scrollTo:handleScrollCombo}
        />
        <div className="plus-icon-container">
          <FaPlus className="plus-icon" />
        </div>
        <CourseCard
          title="Live Interactive Program"
          description="Enjoy the live experience of learning in a transformative setting with cohorts."
          imageSrc="/img/live_interaction_image.png"
          buttonText="View Class Schedule"
          onClick={scrollTo?scrollTo:handleScrollCombo2}
        />
      </div>
    </div>
  );
};

export default LiveCourseSection;