import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LiveCardSingle from "./LiveCardSingle";
import { useMediaQuery } from "react-responsive";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from "react-slick";
import { requestData } from "../../../../utils/baseUrl";
import CustomCard from "../../../../components/SingleShop/Card";
import moment from "moment";

function OnlineEventsCard() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 820 });

  // const {isDesktopOrLaptop,isTablet,isMobile} = useContext(AuthContext)
  const [onlineCourses, setOnlineCourses] = useState([]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    slidesToShow: isDesktopOrLaptop ? 3 : isTablet ? 2 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: isDesktopOrLaptop ? "0%" : isTablet ? "5%" : "70px",
  };

  const getEvents = async () => {
    const res = await requestData("latestClassListbyStartDate", "POST", {
      start_index: 0,
      no_of_records: 1000,
    });
    //console.log(res)
    if (res && res.error === false) {
      const data = res.data.filter((d, i) => {
        return d.delivery_method !== "live";
      });
      setOnlineCourses(data);
      //console.log(data, "Data")
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <div className={`my-5 ${isDesktopOrLaptop ? "container" : ""}`}>
        <div className="d-flex justify-content-between">
          <h3 className="mx-2">Online Events</h3>
          <Link
            to="/events/live"
            style={{
              color: "blue",
              textDecoration: "underline",
              fontWeight: "600",
            }}
          >
            See All
          </Link>
        </div>

        {/* <div className="row livecard">
                    <Slider {...settings}>
                        {
                            onlineCourses && onlineCourses.map((lc, i) => (
                                <div key={i} className="col-3 p-2">
                                    <LiveCardSingle data={lc} />
                                </div>
                            ))
                        }
                    </Slider>
                </div> */}

        <div>
          <Slider {...settings}>
            {onlineCourses && onlineCourses?.map((cl,i)=>
               <Link className="p-2" to={`/events/livedetails/${cl?.course_id}`}>
                <CustomCard
                index={i}
                ins_image={cl?.photo || require("../../../../images/no-photo-icon-22.png")}
                title={cl?.course_name}
                ins_name={cl?.instructor_name}
                date={`${moment(cl?.start_date).format("Do MMM")}- ${moment(cl?.end_date).format("Do MMM YYYY")}`}
                language={cl?.language}
                mode={cl?.delivery_method}
                borderRadiusAll
              />
              </Link>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default OnlineEventsCard;
