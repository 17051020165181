import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'

function NewPara({
    para,
    start,
    
}) {
    const { isMobile } = useContext(AuthContext);

    return (
        <h5 className={start?"mt-3 mb-3 text-start robotofont":"mt-3 mb-3 text-center robotofont"} style={{
            lineHeight: isMobile ? "1.3rem" : "1.5rem",
            fontSize: isMobile ? "0.9rem" : "1rem",
            fontWeight:"400",

            color: "#000"
        }}

        >
            {para}

        </h5>
    )
}

export default NewPara