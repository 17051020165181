
import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { AuthContext } from "../../context/AllContext";
import HomeHeading from "../Home/Home/HomeHeading";
import NewReviewsCard from "../../components/featured/NewReviewsCard";

// import "./styles.css";

// import required modules

export default function Testimonials2() {
  const { isDesktopOrLaptop, isTabletOrMobile } = useContext(AuthContext);


  const dataas = [
    {
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Balkrishna_Doshi.JPG/800px-Balkrishna_Doshi.JPG",
      name:"Dr. Balakrishna V. Doshi",
      comment:"I find Silva helps in reducing mental stress and attaining peace of mind",
      designation:"PADMA BHUSHAN & PRITZKER AWARDEE"
    },
    {
      image:"https://static.toiimg.com/thumb/msid-50682276,imgsize-9723,width-400,resizemode-4/50682276.jpg",
      name:"Mallika Sarabhai",
      comment:"Silva can be beneficial for everyone",
      designation:"Padma bhushan classical dancer & social activist"
    },
    {
      image:"https://timesofindia.indiatimes.com/photo/msid-69817782/69817782.jpg?resizemode=4",
      name:"Yashovardhan Birla",
      comment:"With Silva you can enhance and main your health and fitness",
      designation:"Director, Yash Birla Group"
    },
   
    {
      image:"https://m.media-amazon.com/images/M/MV5BYWRlMTk2NWYtYjhjNy00NmRjLTg4NWMtMmM2YjJlN2YwNWE4XkEyXkFqcGdeQXVyMTExNDQ2MTI@._V1_FMjpg_UX1000_.jpg",
      name:"Amrish Puri",
      comment:"I learned a lot about meditation from the Silva Classes",
      designation:"Hindi film actor"
    },
    {
      image:"https://www.filmibeat.com/img/315x100x396/popcorn/profile_photos/sanjay-dutt-20230203182745-98.jpg",
      name:"Sanjay Dutt",
      comment:"I learned the art of Silva meditation and it helps in positive thinking",
      designation:"Film actor,author & producer"
    }
  ]

  const [reviews, setReviews] = useState([]);

  const settings = {
    className: "center",
    infinite: true,
    cssEase: "linear",
    centerMode: true,
    centerPadding: isDesktopOrLaptop ? '20%' : "10%",
    slidesToShow: isDesktopOrLaptop ? 1 : 1,
    speed: 500,
    prevArrow: <GrFormPrevious />,
    nextArrow: <GrFormNext />,
    dots: true,
    autoplay: true
  };

  const getreviews = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "1000");
    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
      myHeaders.append("country", country.toString())
    }

    if (currency) {
      myHeaders.append("currency", currency.toString())
    }
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: formData
    }
    const res = await fetch(`https://silvamethod.com/manage/api/testimonialList`, options)
    const data = await res.json();
    //console.log(data.data.classes);
    
    setReviews([...dataas,...data.data]);
  }

  useEffect(() => {
    getreviews()
  }, []);

  // console.log(reviews);



  return (
    <div className={isTabletOrMobile ? "mt-0 mb-0" : "mt-20 mb-40"}>
      <div className="row justify-content-center">
       
        <HomeHeading title="What Others Say about" colored="Silva Method" title2="" />
        <h5 className="mt-3 mb-3 text-center">
          Over 12 million people in 110 countries have experienced what Silva
          Method Meditation can do.
        </h5>
        <div className="col-12 text-center bestslider">

          <Slider {...settings}>
            {
              reviews.map((val, i) => (
                <NewReviewsCard key={i} value={val} />

              ))
            }

          </Slider>

        
        </div>
      </div>
    </div>
  );
}

