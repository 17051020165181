import React from 'react'
import { Col, Row } from 'react-bootstrap'
import HeadingNew from '../../AboutUs/HeadingNew'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { Link } from 'react-router-dom'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'

function AboutAuthor() {
    const { isMobile } = useContext(AuthContext)
    return (
        <div className='py-5 container' style={{
            padding: isMobile ? "30px" : "0px"
        }}>
            {/* <h2>Hello world</h2> */}
            <Row className='justify-content-center align-items-center py-5'>
                <Col xs={12} md={6}>
                    <div style={{
                        position: "relative",
                        width: "100%"
                    }}>
                        <img src='/img/book/img-19.webp' style={{
                            width: isMobile ? "100%" : "80%",
                            borderRadius: 15,
                            zIndex: 1

                        }} />
                        <img src='/img/iconbookpage/icon-07.svg' style={{
                            position: "absolute",
                            top: "-70px",
                            left: "-180px",
                            zIndex: -1
                        }} />

                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div style={{
                        marginTop: isMobile ? "20px" : "0px"
                    }}>
                        <HeadingNew title={"About The Author"} start />
                    </div>
                    <div style={{
                        width: "100px",
                        height: "30px",
                        backgroundColor: "#EEEEEE",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        marginTop: 7

                    }}>
                        Jose Silva

                    </div>
                    <div className='d-flex justify-content-start gap-4 align-items-center mt-2 flex-wrap'>
                        <div className='d-flex gap-2 align-items-center'>
                            <img src='/img/iconbookpage/icon-09.svg' width={40} height={40} />
                            <p style={{
                                fontSize: "0.9rem",
                                padding: 0,
                                margin: 0,
                                fontWeight: "400"
                            }} className='text-muted'>132 Books</p>

                        </div>
                        <div>
                            <div className='d-flex gap-2 mt-3'>
                                <div class="avatars2" style={{
                                    position: "relative"
                                }}>
                                    <span class="avatar">
                                        <img src="/img/icon/followers/stockfollower4.jpg" width="40" height="40" style={{
                                            zIndex: 1
                                        }} s />
                                    </span>
                                    <span class="avatar">
                                        <img src="/img/icon/followers/stockfollower3.jpg" width="40" height="40" style={{
                                            zIndex: 2
                                        }} s />
                                    </span>
                                    <span class="avatar">
                                        <img src="/img/icon/followers/stockfollower2.jpg" width="40" height="40" style={{
                                            zIndex: 3
                                        }} />
                                    </span>
                                    <span class="avatar">
                                        <img src="/img/icon/followers/stockfollower1.jpg" width="40" height="40" style={{
                                            zIndex: 4
                                        }} />
                                    </span>

                                </div>
                                <p className='text-muted mt-1' style={{
                                    fontWeight: "500"
                                }}>
                                    78,000+ followers
                                </p>
                            </div>
                        </div>
                        <div>
                            <button className='bookbtn'>
                                Follow Silva Method

                            </button>

                        </div>

                    </div>

                   <ParaComp
                    data={[
                        "Jose Silva's life is more than a great American success story. It has transcended time and space to become one of the world's all time great success stories.",
                        "Orphaned at age 4, he never attended school a day in his life as a student; yet the reading and research that he undertook to help his own children, unlocked secrets of the mind and human potential that had remained hidden for thousands of years."
                    ]}
                    white={false}
                    fw={"400"}
                   />

                    <div className='d-flex justify-content-start align-items-center mt-3 flex-wrap' style={{
                        gap: isMobile ? "20px" : "70px"
                    }}>
                        <Link to={'/jose-silva-method'}>
                        <button className='styledbtnbook'>
                            Read More {`>`}
                        </button>
                        </Link>

                        <div className='d-flex flex-column justify-content-center gap-1'>
                            <span className='text-muted'>Join Silva Community</span>
                            <div className='d-flex gap-1'>
                                <a href="https://www.facebook.com/SilvaInternationalInc">
                                <img src='/img/iconbookpage/icon-10.svg' width={40} height={40} />
                                </a>
                                <a href=" https://www.youtube.com/@SilvaMethodOfficial">
                                <img src='/img/iconbookpage/icon-11.svg' width={40} height={40} />
                                </a>
                                <a href="https://www.instagram.com/silvamethodofficial/">
                                <img src='/img/iconbookpage/icon-12.svg' width={40} height={40} />
                                </a>
                                <a href="https://www.linkedin.com/company/thesilvamethods/">
                                <img src='/img/iconbookpage/icon-13.svg' width={40} height={40} />
                                </a>
                                <a href="https://www.pinterest.com/the_silvamethod/">
                                <img src='/img/iconbookpage/icon-14.svg' width={40} height={40} />
                                </a>
                                <a href="https://x.com/SilvaHomeOffice">
                                <img src='/img/iconbookpage/icon-15.svg' width={40} height={40} />
                                </a>
                                <a href="https://www.tiktok.com/@silvamethodinternational">
                                <img src='/img/iconbookpage/icon-16.svg' width={40} height={40} />
                                </a>
                                
                               
                               
                                
                               
                                
                            </div>


                        </div>

                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default AboutAuthor