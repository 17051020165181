import React from 'react'
import HeadingNew from '../../AboutUs/HeadingNew'
import ParaComp from '../../SilvaManifestationProgram/ParaComp'
import NewPara from './NewPara'

function Psychorientology() {
    return (
        <div className='container my-5'>
             <div className='d-flex justify-content-center align-items-center flex-column'>
            <HeadingNew
                title={"The Silva Method: "}
                colored={"A Psychorientology"}
            />

            <img src='/img/bg/vtdaubti4bzufga7hhpq.jpg' style={{
                width:"80%",
                borderRadius:"15px"
            }}
            className='my-3'
            />

            <NewPara
                para={
                    "Initially, José Silva faced skepticism, from his friends even from his wife Paula, until she eventually saw the positive effects on their children. Eventually, Silva’s work attracted the attention of one scientist, who became a close friend and associate and brought the research findings to the attention of many other scientists. His pioneering research, overcoming numerous challenges, created lasting memories and marked the first step in human evolution through metaphysics. This is how scientists discovered the new science Silva named “Psychorientology”."

                }
            />

        </div>

        </div>
    )
}

export default Psychorientology