import React from 'react'

import NewHeader from '../../AdvertisePages/newmembership/NewHeader'
import HeadingComp from '../../SilvaManifestationProgram/HeadingComp'
import HeadingNew from '../../AboutUs/HeadingNew'
import TestimonialsHero from './TestimonialsHero'
import TestimonialGrid from './TestimonialGrid'
import { useState } from 'react'
import { AuthContext } from '../../../context/AllContext'
import { useContext } from 'react'
import { checkIsCoursePurchased, reqData } from '../../../utils/baseUrl'
import { useEffect } from 'react'
import ComboHero2 from '../ComboCourse/ComboHero2'
import ComboIncludes from './ComboIncludes'
import { Fade } from 'react-awesome-reveal';
import ComboBenefitsSection from './ComboBenefitsSection'
import LiveInteractiveBenefits from './LiveInteractiveBenefits'
import AllCourseAccordions2 from './AllCourseAccordions2'
import TabbarSection from '../ComboCourse/TabbarSection'
import BreakThroughs2 from './BreakThroughs2'
import ComboContactSection from './ComboContactSection'
import ComboFooter from '../ComboCourse/ComboFooter'
import NewComboBanner from '../ComboCourse/NewComboBanner'
import HomePageHeroSection from '../../CoursePage2/HomePageHeroSection'

function Testimonials() {

    const [courseData, setCourseData] = useState([]);

    const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);

    const { userData } = useContext(AuthContext);

    const [allCourseData, setAllCouurseData] = useState();

    const [loading, setLoading] = useState(false);

    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });
        setLoading(false);
        if (res.error === false) {
            setCourseData(res.data);
        }
    };



    const getAllCourses = async () => {
        setLoading(true);
        const res = await reqData("latestCourseList", "POST", {
            start_index: 0,
            no_of_records: 1000,
        });



        if (res && res.error === false) {
            const allc = res.data
                .filter(
                    (val) =>
                        val.course_id !== "combo-plan" &&
                        val.course_id !== "silva-method-complete-course-with-live-interactive-program" && val.course_id !== "unstress-toolbox"
                )
                .map((val) => val.course_id);

            const promisesArr = [];

            allc.forEach((cid) => {

                promisesArr.push(
                    reqData("courseDetail", "POST", {
                        course_id: cid,
                    })
                );
            });

            const result = await Promise.all(promisesArr);
            setLoading(false);

            const finalResult = result
                .map((val) => {
                    if (val.error === false) {
                        return val.data;
                    }
                })
                .sort((a, b) => a[0].precedence - b[0].precedence);

            setAllCouurseData(finalResult);
        }
    };



    const getPurchasedStat = async () => {
        const status = await checkIsCoursePurchased("combo-plan", userData);
        setIsCurrentCoursePurchased(status)
    }


    useEffect(() => {
        getCourseData();
        getAllCourses();
        getPurchasedStat();
    }, [userData]);


    useEffect(() => {
        const docs = document.querySelector(".bgnewcombobenefits222");
        if (docs) {
            docs.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, []);


    return (
        <>
            <NewHeader />

            <div className='bgnewcombobenefits222' style={{
                marginTop: 61,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <TestimonialsHero />

            </div>
            <div className='py-5'>
                <TestimonialGrid />
            </div>
            {/* <ComboHero2 data={courseData} isCurrentCoursePurchased={isCurrentCoursePurchased} alterbackground /> */}
            <HomePageHeroSection />
            <Fade duration={700}>
                <ComboIncludes />
            </Fade>
            <Fade>

                <div className='my-5'>
                    <ComboBenefitsSection />

                </div>
            </Fade>
            {/* <NewComboBanner/> */}
            <Fade>
                <div className='mt-5'>
                    <LiveInteractiveBenefits />

                </div>
            </Fade>
            <Fade>
                <BreakThroughs2 />
                <div>
                    <HeadingNew title={" Silva Method Complete Course Curriculum"} />
                </div>
            </Fade>
            {
                allCourseData &&
                allCourseData.map((value, i) => (
                    <AllCourseAccordions2
                        data={value[0]}
                        isCurrentCoursePurchased={isCurrentCoursePurchased}
                        lastcorused={i}
                    />

                ))}
            <Fade>
                <div className='py-1'>
                    <TabbarSection />
                </div>

            </Fade>
            <div className='py-5'>
                <ComboContactSection />

            </div>




            <ComboFooter />

        </>
    )
}

export default Testimonials