import React from "react";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import ComBoPlusLiveHero from "./ComBoPlusLiveHero";
import Featured from "../../../components/featured/Featured";
import ComboPlusLiveSection4 from "./ComboPlusLiveSection4";
import ComboPlusLiveFaqs from "./ComboPlusLiveFaqs";
import { useState } from "react";
import { useEffect } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import { checkIsCoursePurchased, reqData } from "../../../utils/baseUrl";
import CustomFooter from "../../Home/Home/HomeFooter/Footer";
import ComboHero2 from "../ComboCourse/ComboHero2";
import TFTFaqs from "../ThreeFingerTechnique/TFTFaqs";
import RefundBanner from "../../CoursePage/RefundBanner";
import PopularSection from "../../ComboLiveLanding/One/PopularSection";
import ReviewsContainer from "../../../components/DifferenceSection/ReviewsContainer";
import ContactSectionNew from "../../payment/ContactNewSection";
import CourseFeatures from "../../ComboLiveLanding/One/CourseFeatures";
import BenifitsCards from "../GlassofWaterBenifits";
import EnrollmentDetails from "./EnrollmentDetails";
import HomePageHeroSection from "../../CoursePage2/HomePageHeroSection";

function ComboPlusLive() {
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allCourseData, setAllCouurseData] = useState();
  const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] =
    useState(false);
  const { userData } = useContext(AuthContext);

  const faqsData = [
    {
      id: 1,
      heading: "How can I learn to manifest?",
      body: "Learning to manifest is easier than you think! Our manifestation courses provide step-by-step guidance and practical exercises to help you master the art of manifestation and harness the power of the law of attraction.",
    },
    {
      id: 2,
      heading: "What is the best manifestation course? ",
      body: "The best manifestation course is one that aligns with your goals, values, and learning style. Our Silva Method manifestation courses are highly acclaimed for their comprehensive curriculum, expert instruction, and proven results.",
    },
    {
      id: 3,
      heading: "Can I manifest love, money, success, and abundance?",
      body: "Absolutely! Our manifestation courses are designed to help you manifest all areas of your life, including love, money, success, and abundance. With the right mindset and techniques, anything is possible.",
    },
    {
      id: 4,
      heading: "How do I enroll in the manifestation course? ",
      body: "Enrolling in our manifestation course is easy! Simply visit our website, choose the course that best suits your needs, and follow the instructions to complete the enrollment process. Once enrolled, you'll gain immediate access to course materials and live sessions.",
    },
  ];

  const courseFeaturesData=[
    {
      id:1,
      title:'Comprehensive Curriculum',
      text:'Dive into a comprehensive curriculum covering all aspects of manifestation and the law of attraction, including practical exercises and real-life applications.'
    },
    {
      id:2,
      title:'Live Interactive Sessions',
      text:"Participate in live interactive sessions led by certified manifestation experts, where you'll receive personalized guidance and support to amplify your manifesting abilities."
    },
    {
      id:3,
      title:'Practical Exercises',
      text:"Engage in practical exercises designed to strengthen your manifestation skills and deepen your understanding of the law of attraction principles."
    },
    {
      id:4,
      title:'Manifesting Love, Money, Success & Abundance',
      text:"Learn how to manifest love, money, success, and abundance using powerful manifestation techniques tailored to your individual goals and desires."
    },
    {
      id:5,
      title:'Lifetime Access',
      text:"Enjoy lifetime access to course materials, allowing you to revisit lessons and continue your manifesting journey at your own pace."
    }
  ];

  const benifitsData=[
    {
        id:1,
        text:'Experience a newfound sense of empowerment as you learn to consciously create your reality and manifest your dreams into existence.',
        title:'Empowerment',
        image: <img src="/img/online-menifestation/icons/icon_empowerment.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'10px'}}/>,
    },
    {
        id:2,
        text:'Cultivate an abundance mindset that attracts wealth, success, and opportunities into your life effortlessly.',
        title:'Abundance Mindset',
        image:<img src="/img/online-menifestation/icons/icon_abandance-mindset.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'10px'}}/>,
    },
    {
        id:3,
        text:"Achieve a deeper sense of fulfillment and satisfaction as you align with your true purpose and manifest your heart's desires.",
        title:'Fulfillment',
        image:<img src="/img/online-menifestation/icons/icon_fulfillment.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'10px'}}/>,
    },
    {
        id:4,
        text:'Experience transformative growth on a personal and spiritual level as you tap into the limitless potential of the law of attraction.',
        title:'Transformative Growth',
        image:<img src="/img/online-menifestation/icons/icon_transformative-groth.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'10px'}}/>,
    },
    {
        id:5,
        text:'Manifest harmonious and fulfilling relationships that support your growth and enhance your overall well-being.',
        title:'Positive Relationships',
        image:<img src="/img/online-menifestation/icons/icon_positive-relationship.svg" style={{width:50,height:50, objectFit:'contain',marginBottom:'10px'}}/>,
    },
];





  const getCourseData = async () => {
    setLoading(true);
    const res = await reqData("courseDetail", "POST", {
      course_id: "silva-method-complete-course-with-live-interactive-program",
    });
    setLoading(false);
    if (res.error === false) {
      setCourseData(res.data);
    }
  };

  const getAllCourses = async () => {
    setLoading(true);
    const res = await reqData("latestCourseList", "POST", {
      start_index: 0,
      no_of_records: 20,
    });

    if (res && res.error === false) {
      const allc = res.data
        .filter(
          (val) =>
            val.course_id !== "combo-plan" &&
            val.course_id !==
              "silva-method-complete-course-with-live-interactive-program" &&
            val.course_id !== "unstress-toolbox"
        )
        .map((val) => val.course_id);

      const promisesArr = [];

      allc.forEach((cid) => {
        promisesArr.push(
          reqData("courseDetail", "POST", {
            course_id: cid,
          })
        );
      });

      const result = await Promise.all(promisesArr);
      setLoading(false);

      const finalResult = result
        .map((val) => {
          if (val.error === false) {
            return val.data;
          }
        })
        .sort((a, b) => a[0].precedence - b[0].precedence);

      setAllCouurseData(finalResult);
    }
  };

  const getPurchasedStat = async () => {
    const status = await checkIsCoursePurchased(
      "silva-method-complete-course-with-live-interactive-program",
      userData
    );
    setIsCurrentCoursePurchased(status);
  };

  useEffect(() => {
    getCourseData();
    getAllCourses();
    getPurchasedStat();
  }, [userData]);

  if (error) {
    <div className="d-flex justify-content-center align-items-center">
      <h2>An Error Occured ,Please try again later</h2>
    </div>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <HomeHeader />
      <ComBoPlusLiveHero />
      <Featured />
      <CourseFeatures data={courseFeaturesData} image={'/img/online-menifestation/2nd-sec.png'}/>
      <PopularSection />
      <BenifitsCards data={benifitsData} title={'Benefits'}/>
      {/* <ComboHero2
        data={courseData}
        isCurrentCoursePurchased={isCurrentCoursePurchased}
      /> */}
       <HomePageHeroSection />
      {/* <NewComboBanner/> */}
     
      {/* <ComboPlusLiveFaqs data={faqsData} /> */}
      <div className="my-5">
      <ComboPlusLiveSection4 />

      </div>
      <div className="container mt-5">
        <RefundBanner />
      </div>
      <TFTFaqs data={faqsData} />
      <EnrollmentDetails/>
      <ReviewsContainer />
      <ContactSectionNew image={'/img/online-menifestation/footer-img.png'}/>
      <CustomFooter />
    </div>
  );
}

export default ComboPlusLive;
