import React from 'react'
import { MdCheckCircle } from "react-icons/md";
import NewPara from './NewPara';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { useState } from 'react';
import { TbPointFilled } from "react-icons/tb";

function TickSection({ data }) {
    const { isMobile } = useContext(AuthContext);

    const [itemsToShow,setItemsToShow] = useState(data.slice(0,6));

    const [showMore,setShowMore] = useState(false);


    return (
        <div className='d-flex justify-content-center align-items-start flex-column'>
            {
                itemsToShow.map((val) => (
                    <div className='d-flex align-items-start gap-2'>
                        <span>
                            <TbPointFilled color='black' size={15} />

                        </span>
                        <h5 className={"text-start robotofont"} style={{
                            lineHeight: isMobile ? "1.3rem" : "1.5rem",
                            fontSize: isMobile ? "0.9rem" : "1rem",
                            fontWeight:"400",

                            color: "#000"
                        }}

                        >
                            {val}

                        </h5>


                    </div>
                ))
            }



           {
            data.length>6 && (
                <button className='cssbuttons-io-button3' onClick={()=>{
                    if(showMore){
                        setItemsToShow(data.slice(0,6))
                        setShowMore(false);

                    }else{
                        setItemsToShow(data)
                        setShowMore(true);
                    }
                }}>
                    {
                        showMore ?"Show Less":"Show More"
                    }

                </button>
            )
           }


        </div>
    )
}

export default TickSection