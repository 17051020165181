import React, { useState, useEffect, useContext } from 'react';
import { checkIsCoursePurchased, requestData } from '../../../utils/baseUrl';
import LoadingSpinner from '../../LoadingSpinner';
import { useMemo } from 'react';
import HeadingNew from '../../AboutUs/HeadingNew';
import { AuthContext } from '../../../context/AllContext';
import { Image, Offcanvas } from 'react-bootstrap';
import CourseAccodions from '../../CoursePage/CourseAccodions';
import { IoIosClose } from "react-icons/io";
import ShineBtn from '../SilvaComboCourse/ShineBtn';

function ComboIncluded2({ func,courses,isCurrentCoursePurchased }) {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const st_id = localStorage.getItem("token")

    const { userData: udata, isMobile } = useContext(AuthContext);


    const handleClose = () => {
        setShow(false)
        setCourseDetails(null)
    };
    const handleShow = () => setShow(true);

    const [courseDetails, setCourseDetails] = useState(null);

   







  

    const images = useMemo(() => {
        return [

            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(1).webp",
            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(4).webp",
            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(5).webp",
            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(6).webp",
            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(3).webp",
            "https://silvamethod.com/manage/assets/uploads/Untitled%20design%20(2).webp",
            "https://silvamethod.com/manage/assets/uploads/Image20230824185622.png",
            "https://silvamethod.com/manage/assets/uploads/Weekly_journal_to%20finding_a%20soulmate-2.jpg"
        ]
    }, [])


    useEffect(() => {
        if (courseDetails) {
            handleShow()
        }
    }, [courseDetails])

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className='pt-5 pb-5'>
                <HeadingNew title={"Courses included in the"} small />
                <HeadingNew title={"Silva Method"} colored={"Combo Course"} />

                <div className="container containercombonew">
                    {courses.map((val, index) => (
                        <div className="wrapperbro" key={index}>
                            <div className="cardbro cardbro2">
                                <img src={val[0]?.web_image} alt={`Image ${index + 1}`} 
                                // onClick={() => {
                                //     setCourseDetails(null)
                                //     // getCourseDetails(val.course_id)
                                //     const found = courses.find((value, i) => {
                                //         return value[0].course_id === val[0].course_id
                                //     })

                                //     if (found) {
                                //         setCourseDetails(found[0])
                                //     }

                                // }}
                                onClick={func}
                                 />
                                {/* <div className="d-flex gap-2 align-items-center">
                                    <i className="fal fa-arrow-right"></i>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <ShineBtn onClick={func} text={isCurrentCoursePurchased?"Enrolled":"Enroll Now to Unlock All Benefits"} />
                </div>
            </div>
            {
                courseDetails && (
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton className='d-flex justify-content-between'>
                            <Offcanvas.Title className='white-color'>{courseDetails?.course_title}</Offcanvas.Title>
                            <span>
                                <IoIosClose color='white' size={40} onClick={handleClose} />

                            </span>


                        </Offcanvas.Header>
                        <Offcanvas.Body style={{
                            padding: isMobile ? "0 10px 0 10px" : "0 0 0 10px"
                        }}>
                            <div style={{
                                paddingRight: "15px"
                            }}>
                                <Image src={courseDetails.web_image} style={{
                                    borderRadius: "10px"
                                }} />
                            </div>
                            {courseDetails && (
                                <CourseAccodions
                                    lastCourse={false}
                                    data={courseDetails}
                                    isCurrentCoursePurchased={isCurrentCoursePurchased}
                                    whitetitle
                                    searchoff
                                />
                            )}


                        </Offcanvas.Body>
                    </Offcanvas>
                )
            }

        </>
    );
}

export default ComboIncluded2;
