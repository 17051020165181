import React from 'react'
import { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import HeadingNew from '../AboutUs/HeadingNew'
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';

function TestimonialsSection() {
    const [reviews, setReviews] = useState([]);

    const getreviews = async () => {
        const myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");

        const formData = new FormData();
        formData.append("start_index", "0");
        formData.append("no_of_records", "1000");


        const country = localStorage.getItem("countryname");
        const currency = localStorage.getItem("currency")


        if (country) {
            myHeaders.append("country", country.toString())
        }

        if (currency) {
            myHeaders.append("currency", currency.toString())
        }
        const options = {
            method: 'POST',
            headers: myHeaders,
            body: formData
        }
        const res = await fetch(`https://silvamethod.com/manage/api/testimonialList`, options)
        const data = await res.json();
        //console.log(data.data.classes);
        setReviews(data.data.reverse());
    }

    useEffect(() => {
        getreviews()
    }, []);


    return (
        <div className='container d-flex justify-content-center align-items-center flex-column gap-3 py-5'>
            {/* <HeadingNew title={""} /> */}
            <h2 style={{
                color:"black",
                fontWeight:"700"
            }}>
            What people are saying about Silva Method
            </h2>
            <p style={{
                color:"black",
                fontSize:"1.2rem",
                fontWeight:"600"
            }}>
            Nothing speaks for The Silva Method better than success stories from our very own graduates.
            </p>
            <Row className='container justify-content-center align-items-center g-3'>
                {reviews.map((val, i) => (
                    <Col xs={12} md={6} lg={3}>
                        <TestiCard value={val} />
                    </Col>
                ))}


            </Row>
        </div>

    )
}

export default TestimonialsSection


function TestiCard({ value }) {

    const {isMobile} = useContext(AuthContext);


    const [readMore, setReadMore] = useState(false);

    return (
        <Card style={{
            margin: "0",
            padding: "0",
            borderRadius: "10px",
            width: isMobile?"100%":"95%",
            height:readMore?"auto":"580px"
        }}>
            <Card.Body style={{
                margin: "0",
                padding: "0",
            }}>
                <Card.Img src={value.image}
                    height={300}
                    style={{
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        objectFit:"cover"
                    }}
                />
                {/* <Card.Title className='mx-3' style={{
                    color: "black",
                    fontWeight: "600",
                    fontSize: "1.5rem",
                    marginTop: "10px"
                }}>
                    {value.comment.substring(0, 100)}
                </Card.Title> */}
                <Card.Text className='mx-3 mt-3' style={{
                    color: "black",
                    fontSize: "1rem"
                }}>
                    {readMore ? value.comment : value.comment.substring(0, 100) + "..."}

                </Card.Text>
                <a className='mx-3' style={{
                    color: "blue",
                    fontSize: "1.1rem",
                    fontWeight: "700"

                }}
                    onClick={() => {
                        setReadMore(!readMore)
                    }}
                >
                    {readMore ? "Read Less":"Read More"}
                </a>

                <div className='my-3 d-flex justify-content-center align-items-start flex-column mx-3'>
                    <h5 style={{
                        color: "black",
                        fontWeight: "600"
                    }}>{value.name}</h5>
                    <h6 style={{
                        fontWeight: "500"

                    }}>
                        {value.designation}
                    </h6>
                </div>

            </Card.Body>
        </Card>
    )
}