import React, { useContext, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap';
import ParaComp from '../../SilvaManifestationProgram/ParaComp';
import HeadingNew from '../../AboutUs/HeadingNew';
import { AuthContext } from '../../../context/AllContext';

function LiveInteractiveBenefits() {
    const {isMobile} = useContext(AuthContext);

    const data = useMemo(() => {
        return [
            {
                img: "/img/combo/demo2.gif",
                title: "In-Depth Learning",
                text: "Dive deep with certified instructors for a thorough understanding of the Live Interactive Program."
            },
            {
                img: "/img/combo/demo3.gif",
                title: "Collaborative Practice",
                text: "Apply and practice in a supportive environment with like-minded peers on the same learning journey."
            },
            {
                img: "/img/combo/demo4.gif",
                title: "Ask- the Instructor",
                text: "Get real-time answers – ask questions and clear doubts instantly with experienced instructors."
            },
            {
                img: "/img/combo/demo5.gif",
                title: "Hands-On Experience",
                text: "Immerse in practical learning through live interactions, boosting understanding and skill application"
            },
            {
                img: "/img/combo/demo1.gif",
                title: "Personalized Guidance",
                text: "Get tailored learning experiences with personalized guidance, meeting your specific needs and goals."
            }
        ]

    }, []);
    return (
        <div className='container' style={{
            paddingTop: isMobile?0: 50,
            paddingBottom: 50,

        }}>

            <HeadingNew title={"Benefits of"} colored={"Silva Live Interactive Classes"} />
            <Row className='justify-content-center align-items-center mt-4' style={{
                paddingLeft:isMobile?10:0,
                paddingRight:isMobile?10:0
            }}>
                {
                    data.map((val, i) => (
                        <Col xs={12} md={6} lg={4}>
                            <div class="card-list">
                                

                              
                                <article class="cardrespons cardresponsnew">
                                    <figure class="card-image2222" style={{
                                        overflow:"hidden",
                                        position:"relative",
                                        zIndex:1
                                    }}>
                                        <img src={val.img} width={100} height={100} style={{
                                            objectFit:"cover",
                                            borderRadius:10
                                        }} className='cardimgcombo222' alt="An orange painted blue, cut in half laying on a blue background" />
                                    </figure>
                                    <div class="card-header2222">

                                        <a href="#" style={{
                                            color: "white",
                                            position:"relative",
                                            zIndex:1
                                        }}>
                                            {val.title}
                                        </a>

                                    </div>

                                    <div class="card-footer2222">
                                        <div class="card-meta card-meta--views">
                                            
                                            <ParaComp
                                                white={true}
                                                margin={false}
                                                data={[
                                                    val.text
                                                ]}
                                            />
                                        </div>

                                    </div>

                                </article>
                            </div>
                        </Col>

                    ))
                }

            </Row>

        </div>
    )
}

export default LiveInteractiveBenefits