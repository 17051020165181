import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { AuthContext } from '../../../context/AllContext';
import { useRef } from 'react';
import { checkIsCoursePurchased, reqData } from '../../../utils/baseUrl';
import LoadingSpinner from '../../LoadingSpinner';
import { useEffect } from 'react';
import { polyfill, scrollIntoView } from 'seamless-scroll-polyfill';
import NewCourseSectionHero from '../../SingleCoursePage/NewCourseSectionHero';
import NewCourseDetailsBannerSection from '../../SingleCoursePage/NewCourseDetailsBannerSection';
import BenefitsSectionCourse from '../../SingleCoursePage/BenefitsCourseSection';
import HighlightSection from '../../SingleCoursePage/SuccessBannerSection';
import CustomImageGalleryWithSidebar2 from './CustomGalleryWithSidebar2';
import { Card } from 'react-bootstrap';
import HeadingNew from '../../AboutUs/HeadingNew';
import ComboTestimonials from '../SilvaComboCourse/ComboTestimonials';
import AboutSilvaMethod from '../../CoursePage2/AboutSilvaMethod';
import TestimonialSliderCombo from '../ComboCourse2/TestimonialSliderCombo';
import MobileSection from '../../CoursePage2/MobileSection';
import SilvaLifeFAQ from '../../SilvaManifestationProgram/SilvaLifeFAQ';
import ContactSection from '../../CoursePage/ContactSection';
import CustomFooter from '../../Home/Home/HomeFooter/Footer';
import LiveCourseSection from '../ComboCourse/LiveCourseSection';
import AddOn from '../ComboCourse/AddOn';
import NewLiveCard from '../ComboCourse/NewLiveCard';
import LiveInteractiveBenefits from '../ComboCourse2/LiveInteractiveBenefits';
import SilvaComboFAQ from '../ComboCourse/SilvaComboFAQ';
import BetterSection2 from '../SilvaComboCourse/BetterSection2';
import ComboIncluded from '../SilvaComboCourse/ComboIncluded';
import ComboIncluded2 from './ComboIncluded2';
import Featured from '../../../components/featured/Featured';

function NewComboCourse() {

    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allCourseData, setAllCouurseData] = useState();
    const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);
    const { userData, isMobile } = useContext(AuthContext);
    const courseDescRef = useRef(null)
    const [showCard, setShowCard] = useState(false);



    const scrollTo = () => {
        polyfill()
        const doc = document.querySelector(".newpaymentcard");
        setTimeout(() => {

            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 300)
    }


    const scrollToNew = () => {
        polyfill()
        const doc = document.querySelector(".bannerdownloadcurri");
        setTimeout(() => {

            scrollIntoView(doc, {
                behavior: "smooth",
                block: "end", inline: "nearest"
            });
        }, 300)
    }


    const getCourseData = async () => {
        setLoading(true);
        const res = await reqData("courseDetail", "POST", {
            course_id: "combo-plan",
        });

        setLoading(false);

        // console.log(res.data[0].course_description, "Response")

        // if (res && res.data && res.data[0].course_description && courseDescRef) {
        //     setCourseDesc(res.data[0].course_description)
        //     courseDescRef.current.innerHTML = res.data[0].course_description
        // }
        if (res.error === false) {
            setCourseData(res.data);
            getAllCourses(res.data[0].included_course)
            // included_course
        }
    };




    const getAllCourses = async (coursearr) => {
        setLoading(true);


        const promisesArr = [];

        coursearr.forEach((cid) => {

            promisesArr.push(
                reqData("courseDetail", "POST", {
                    course_id: cid.course_id,
                })
            );
        });

        const result = await Promise.all(promisesArr);
        setLoading(false);

        const finalResult = result
            .map((val) => {
                if (val.error === false) {
                    return val.data;
                }
            })
            .sort((a, b) => a[0].precedence - b[0].precedence);

        setAllCouurseData(finalResult);
    };

    // console.log(allCourseData,'allCourse')

    const getPurchasedStat = async () => {
        const status = await checkIsCoursePurchased("combo-plan", userData);
        setIsCurrentCoursePurchased(status)
    }




    useEffect(() => {
        getCourseData();
        // getAllCourses();
        if (userData) {
            getPurchasedStat();
        }
    }, [userData, courseDescRef]);






    if (loading) {
        return <LoadingSpinner />;
    }
    return (
        <div className='bgnewcoursedetails'>
            <NewCourseSectionHero coursedata={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased}
                lastCourse={null}
                courseStatusData={null}
                scrollToNew={scrollToNew}
                scrollTo={scrollTo}
                setIsNavbarVisible={true}
            />
            {
                courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && !isCurrentCoursePurchased && (
                    <Featured />
                )
            }
            {
                courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && !isCurrentCoursePurchased && (
                    <LiveCourseSection scrollTo={scrollTo} />
                )
            }

            <NewCourseDetailsBannerSection courseData={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased}
            />


            {
                !isCurrentCoursePurchased && courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && (
                    <BetterSection2 func={scrollTo} />
                )
            }

            {
                isMobile && (
                    <CustomImageGalleryWithSidebar2 courseData={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased}
                        allCourseData={allCourseData}
                    />

                )
            }


            {
                !isCurrentCoursePurchased && !isMobile && (
                    <BenefitsSectionCourse courseData={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} />
                )
            }



            {
                !isCurrentCoursePurchased && !isMobile && (
                    <HighlightSection />
                )
            }

            {
                allCourseData && allCourseData.length > 0 && (
                    <ComboIncluded2 func={scrollTo} isCurrentCoursePurchased={isCurrentCoursePurchased} courses={allCourseData} />

                )



            }


            {
                !isMobile && (
                    <CustomImageGalleryWithSidebar2 courseData={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased}
                        allCourseData={allCourseData}
                    />

                )
            }


            {
                isMobile && courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && (
                    <div className='my-3'>
                        <BenefitsSectionCourse courseData={courseData ? courseData[0] : null} isCurrentCoursePurchased={isCurrentCoursePurchased} />
                        <HighlightSection />
                    </div>
                )
            }

            <div className='container mt-5'>
                {courseData && courseData?.length > 0 && courseData[0].course_description && (
                    <Card
                        style={{ width: "100%", borderRadius: "15px", padding: "20px" }}
                        className=""
                    >
                        <Card.Body style={{ maxWidth: "100%" }}>
                            {/* <h5 className="text-dark">Description :</h5> */}
                            <HeadingNew small title={"Course Description: "} start />
                            <div
                                className="course_desc"
                                // ref={courseDescRef}
                                dangerouslySetInnerHTML={{
                                    __html: courseData[0].course_description
                                }}
                                style={{ color: "black", overflow: "none" }}
                            ></div>
                        </Card.Body>
                    </Card>
                )}

            </div>




            {
                courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && !isCurrentCoursePurchased && (
                    <div className='mt-3'>
                        <AddOn />
                    </div>
                )
            }


            <NewLiveCard setShowCard={setShowCard} />
            {/* <img src="/img/test-image.png" alt="" style={{width:'100%',height:"100%"}}/> */}
            {/* <Benefits599 setShowCard={setShowCard} /> */}
            {
                courseData && courseData[0] && courseData[0].is_combo_course === "Yes" && (
                    <LiveInteractiveBenefits />

                )
            }
            <ComboTestimonials />
            <AboutSilvaMethod />
            <TestimonialSliderCombo />
            <MobileSection />

            {true && (
                <div className="container my-4">
                    <HeadingNew title={"Frequently Asked Questions"} small start />
                    {
                        courseData && courseData[0] && courseData[0].is_combo_course === "Yes" ? (
                            <SilvaComboFAQ />
                        ) : (
                            <SilvaLifeFAQ />
                        )
                    }
                </div>
            )}


            <ContactSection />
            <CustomFooter />
        </div>
    )
}

export default NewComboCourse