import React, { useContext } from "react";
import HeadingNew34 from "../../CoursePage/HeadingNew34";
import { Col, Container, Row } from "react-bootstrap";
import { AuthContext } from "../../../context/AllContext";
import { handleScrollCard } from "../../../utils/handleScrollEmotions";

function NewLiveCard({setShowCard}) {

    const {isDesktopOrLaptop,isMobile,isTablet}= useContext(AuthContext);

  
  return (
    <div
      style={{
        backgroundImage: `url(${isMobile || isTablet ? '/img/bg/interactive_live_bg.webp':'/img/live_interaction_bg.webp'})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: isMobile? "contain":"cover",
        backgroundPosition: "center",
        width: "100%",
        height:isMobile?"850px":"1000px",
      }}
      className="mt-5"
    >
        {/* <div style={{position:"absolute",right:300,top:150,width:'30%', flexWrap:"wrap"}}>
            <HeadingNew34 title={'Silva Method'} colored={"Live Interactive Program"}/>
            <p className='my-2' style={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "start"
                }}>
                  Join the Silva Method Live Interactive Program for a dynamic
                  learning experience. Now, get the chance to practice with
                  recorded audios and embrace live sessions with certified
                  instructors. Receive real-time answers, interact with
                  like-minded people, and practice in a supportive environment.
                  Clear doubts instantly and immerse yourself in practical
                  learning through live interactions. Enjoy tailored experiences
                  with personalized guidance to meet your specific needs and
                  goals.
                </p>
                <div className='d-flex justify-content-end align-items-center w-100'>
                  <button className='herodiv-becomememberbtn3'>
                    Join Now

                  </button>
                </div>
        </div> */}
        <Row className={`h-${isMobile || isTablet? "":"100"} align-items-${isMobile|| isTablet? "start":"center"}`}>
            {(!isMobile || !isTablet ) && <Col sm={12} md={12} lg={7} xl={7}></Col>}
            <Col sm={12} md={12} lg={5} xl={5}>
                <Container>
                <HeadingNew34 title={'Silva Method'} colored={"Live Interactive Program"} start small={(isDesktopOrLaptop || isMobile || isTablet)? true:false}/>
            <p className='my-2' style={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "start",
                  lineHeight: (isDesktopOrLaptop || isMobile || isTablet)? "1.2rem":"2rem",
                }}>
                  Join the Silva Method Live Interactive Program for a dynamic
                  learning experience. Now, get the chance to practice with
                  recorded audios and embrace live sessions with certified
                  instructors. Receive real-time answers, interact with
                  like-minded people, and practice in a supportive environment.
                  Clear doubts instantly and immerse yourself in practical
                  learning through live interactions. Enjoy tailored experiences
                  with personalized guidance to meet your specific needs and
                  goals.
                </p>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <button className='aboutclassbtn3' onClick={handleScrollCard}>
                    Join Now
                  </button>
                </div>
                </Container>
            </Col>
            {(isMobile || isTablet ) && <Col sm={12} md={12} lg={7} xl={7}></Col>}
        </Row>
    </div>
  );
}

export default NewLiveCard;
