import React, { useContext } from "react";
import { Alert, Badge, Card, Col, Container, Image, Row } from "react-bootstrap";
import HeadingNew from "../../AboutUs/HeadingNew";
import { MdAccessTime } from "react-icons/md";
import { AuthContext } from "../../../context/AllContext";
import moment from "moment";
import { useState } from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";

function ZoomDetails() {

  const {isMobile}= useContext(AuthContext);

  const meetingObj={
    topic:"Silva Method's Live Interactive Session Meeting",
    startdate:"2024-09-14T21:25:00",
    enddate:"2024-09-14T23:00:00",
    timeZones:{est:"12:00PM",pst:"9:00AM",london:"5:00PM"},
    meetingLink:"https://zoom.us/j/97927188530?pwd=kPMRATe1AMzvqFeL1ikHEIc3Eg7Zb9.1",
  };

  const [diableBtn,setDisableButton]= useState(true);
  const [show,setShow]= useState(true);


  const buttonClick = () => {
    const currentTime = moment(); // Get current local time
  
    // Convert start and end dates to moments in local time zone
    const startTime = moment(meetingObj.startdate);
    const endTime = moment(meetingObj.enddate);
  
    const isMeetingStart = currentTime.isBetween(startTime, endTime);
    const isMeetingEnd = currentTime.isAfter(endTime);
  
    if (!isMeetingStart && !isMeetingEnd) {
      Swal.fire({
        title: "Not Started",
        text: "The live meeting has not started yet. Please wait...",
        icon: "warning",
      });
    } else if (isMeetingStart && !isMeetingEnd) {
      // Enable the button and open the meeting link
      setDisableButton(false);
      window.open(meetingObj.meetingLink);
    } else if (isMeetingEnd) {
      Swal.fire({
        title: "Meeting End",
        text: "The meeting has ended. Please wait for the next class.",
        icon: "warning",
      });
    }
  };
  

  // console.log(show,'show')

  useEffect(()=>{
    const isMeetingEnd= moment().isAfter(meetingObj?.enddate);
    if(isMeetingEnd){
      setShow(false);
    }
  },[]);

  return (
    <div>
      {show && <Container>
        <Card className="w-100 p-4 m-0" style={{borderRadius:"15px",backgroundImage:"url('/img/bg/bglovemind.jpg')"}}>
          <Row>
            <Col sm={12} md={12} lg={6} className="px-2">
              <div className="w-100 h-100">
                <Image src="/img/live_interaction_image_recoding.jpg" style={{width:"100%",height:"100%",borderRadius:"15px"}}/>
              </div> 
            </Col>
            <Col sm={12} md={12} lg={6} className="px-2 d-flex flex-column justify-content-center">
            <Alert.Heading style={{color:"white"}} className="my-3" as={isMobile?"h4":"h3"}>
            Upcoming <Badge bg="danger">Live</Badge> class information
          </Alert.Heading>
          <div>
            {/* <HeadingNew title={"Topic : Mirror of the mind"} white small start /> */}
            <p style={{fontWeight:"500",fontSize: isMobile?"15px":"20px",color:"white"}}>Topic : {meetingObj?.topic}</p>
          </div>
          <div>
            {/* <HeadingNew
              title={"Date : Saturday, 6th April 2024"}
              white
              small
              start
            /> */}
            <p style={{fontWeight:"500",fontSize: isMobile?"15px":"20px",color:"white"}}>Date : {moment(meetingObj?.startdate).format('dddd, Do MMM YYYY ')}</p>
          </div>
          <div>
            <div className="d-inline-flex flex-wrap mt-3 border border-light p-2 rounded" style={{backgroundColor:"rgba(255, 255, 255, 0.1)"}}>
              <div className="border-end px-3 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                <MdAccessTime color="#fff" />
                <h6 className="text-white">EST - {meetingObj?.timeZones?.est}(USA)</h6>
              </div>
              <div className="border-end px-3 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                <MdAccessTime color="#fff" />
                <h6 className="text-white">PST - {meetingObj?.timeZones?.pst}(USA)</h6>
              </div>
              <div className="px-3 border-light d-flex flex-column justify-content-center align-items-center gap-3">
                <MdAccessTime color="#fff" />
                <h6 className="text-white">London -{meetingObj?.timeZones?.london}</h6>
              </div>
            </div>
          </div>
          <div>
          {/* <Alert.Link href="https://zoom.us/j/94966656526?pwd=dzdlQWxia1ZnTmNaak0vNU54bm9KUT09">Your Meeting Link: Click here</Alert.Link> */}
          <div className="my-3">
          <button className="cssbuttons-io-button" style={{padding:"10px",cursor:diableBtn?"not-allowed":"pointer"}} onClick={buttonClick}>Join Zoom Meeting</button>
          </div>
          <p className="text-white">Button link will be active on {moment(meetingObj?.startdate).format('dddd, Do MMM YYYY ')}(EST- {meetingObj?.timeZones?.est}(USA) / PST- {meetingObj?.timeZones?.pst}(USA)  / London- {meetingObj?.timeZones?.london})</p>
          </div>
            </Col>
          </Row>
        
        </Card>
      </Container>}
    </div>
  );
}

export default ZoomDetails;
