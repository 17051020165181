import React from "react";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

import { useState } from "react";
import { checkIsCoursePurchased, reqData } from "../../../utils/baseUrl";
import { useEffect } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import AddOn from "./AddOn";
import ContactSection from "../../CoursePage/ContactSection";
import ComboFooter from "./ComboFooter";

import { useContext } from "react";
import { AuthContext } from "../../../context/AllContext";
import ComboCouseHeroSection from "./ComboCourseHeroSection";
import TabbarSection from "./TabbarSection";
import ComboBenifitNew from "./ComboBenefitNew";
import PaymentCombo from "./PaymentCombo";
import { useLocation } from "react-router-dom";
import AllCourseAccordions from "../../CoursePage/AllCourseAccordions";
import { Card } from "react-bootstrap";
import { useRef } from "react";
import { useMemo } from "react";
import LiveCourseSection from "./LiveCourseSection";
import TrustedBy from "../../Draganpages/TrustedBy";
import BreakThroughs from "../../silvamembership/BreakThroughs";
import BecomeMemberSection from "../../silvamembership/BecomeMemberSection";
import HeadingNew from "../../AboutUs/HeadingNew";
import ReviewsContainer from "../../../components/DifferenceSection/ReviewsContainer";
import SilvaComboFAQ from "./SilvaComboFAQ";
import NewLiveCard from "./NewLiveCard";
import LiveInteractiveBenefits from "../ComboCourse2/LiveInteractiveBenefits";

function ComboCourse() {
  const [courseData, setCourseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allCourseData, setAllCouurseData] = useState();
  const [isCurrentCoursePurchased, setIsCurrentCoursePurchased] = useState(false);
  const { userData, isBigScreen, isDesktopOrLaptop, isTablet } = useContext(AuthContext);
  const [showCard, setShowCard] = useState(false);
  const courseDescRef = useRef(null)
  const [courseDesc, setCourseDesc] = useState("");



  // console.log(isCurrentCoursePurchased,'purchased?')

  const getCourseData = async () => {
    setLoading(true);
    const res = await reqData("courseDetail", "POST", {
      course_id: "combo-plan",
    });

    // console.log(res.data[0].course_description, "Response")

    if (res && res.data && res.data[0].course_description && courseDescRef) {
      setCourseDesc(res.data[0].course_description)
      // courseDescRef.current.innerHTML = res.data[0].course_description
    }
    setLoading(false);
    if (res.error === false) {
      setCourseData(res.data);
      getAllCourses(res.data[0].included_course)
      // included_course
    }
  };




  const getAllCourses = async (coursearr) => {
    console.log(coursearr, "coursearr")
    setLoading(true);


    const promisesArr = [];

    coursearr.forEach((cid) => {

      promisesArr.push(
        reqData("courseDetail", "POST", {
          course_id: cid.course_id,
        })
      );
    });

    const result = await Promise.all(promisesArr);
    setLoading(false);

    const finalResult = result
      .map((val) => {
        if (val.error === false) {
          return val.data;
        }
      })
      .sort((a, b) => a[0].precedence - b[0].precedence);

    setAllCouurseData(finalResult);
  };

  // console.log(allCourseData,'allCourse')

  const getPurchasedStat = async () => {
    const status = await checkIsCoursePurchased("combo-plan", userData);
    setIsCurrentCoursePurchased(status)
  }




  useEffect(() => {
    getCourseData();
    // getAllCourses();
    getPurchasedStat();
  }, [userData, courseDescRef]);


  const cardDescData = useMemo(() => {
    return (
      <Card style={{ width: "100%", borderRadius: "15px", padding: "20px" }}
        className="coursedescriptioncard">
        <Card.Body style={{ maxWidth: "100%" }} dangerouslySetInnerHTML={{ __html: courseDesc || <div></div> }}>

        </Card.Body>


      </Card>
    )
  }, [courseDesc])



  // console.log(courseData, "courseDetail");

  if (error) {
    <div className="d-flex justify-content-center align-items-center">
      <h2>An Error Occured ,Please try again later</h2>
    </div>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }


  return (
    <div>
      <HomeHeader />

      {
        courseData && courseData[0] && (
          <ComboCouseHeroSection data={courseData} showCard={showCard} setShowCard={setShowCard} />
        )
      }
      <TrustedBy />
      <div className="livecombo container py-5">
        <LiveCourseSection />

      </div>
      {/* <CourseFeaturesSection
          datanew={courseData[0] ? courseData[0] : [{}]}
          isCurrentCoursePurchased={isCurrentCoursePurchased}
        /> */}
      {
        !isCurrentCoursePurchased && (
          <ComboBenifitNew datanew={courseData[0] ? courseData[0] : [{}]} />
        )
      }
      <div className="container my-5">


        <BecomeMemberSection click={false} cta={true} />
      </div>



      <div className="container">
        <div className="scrolldivnew">

          <h2
            style={{
              fontWeight: "800",
              fontSize: isBigScreen ? "40px" : isDesktopOrLaptop ? "40px" : isTablet ? "30px" : "20px",
              margin: "0 0 0 20px",
              textAlign: "center",
              // marginTop: "10px",
              color: "#000"
            }}
          >

            <span style={{ color: "#801cb6", fontWeight: "800", fontFamily: "Poppins" }}>Silva Method </span>{" "} Complete Program Curriculum
          </h2>
        </div>

        {courseData[0] &&
          courseData[0].is_combo_course === "Yes" &&
          allCourseData &&
          allCourseData.map((val, i) => (
            <AllCourseAccordions
              data={val?.length > 0 && val[0]}
              isCurrentCoursePurchased={isCurrentCoursePurchased}
              lastcorused={i}
            />
          ))}

        {courseData && courseData[0] && (
          <div className="container mb-5">

            {/* {
              cardDescData
            } */}

            <div className="mt-4">
              <BreakThroughs />
            </div>



            {/* {courseData && courseData?.length > 0 && (
              <div className="py-3">
                <Stories newdata={courseData[0]} allOff id={id} />
              </div>
            )} */}
          </div>
        )}

      </div>
      <AddOn />
      {/* <LiveSection isCurrentCoursePurchased={isCurrentCoursePurchased} setShowCard={setShowCard} /> */}
      <NewLiveCard setShowCard={setShowCard} />
      {/* <img src="/img/test-image.png" alt="" style={{width:'100%',height:"100%"}}/> */}
      {/* <Benefits599 setShowCard={setShowCard} /> */}
      <LiveInteractiveBenefits />
      <TabbarSection />


      {/* <PopularSection /> */}
      {!isCurrentCoursePurchased && <ReviewsContainer />}

      {/* {!isCurrentCoursePurchased && (
        <div className="container my-5">
          <HeadingNew title={"Frequently Asked Questions About Silva Method Combo Course"} small start />
        </div>
      )} */}

      <div className="container belowpayment mt-4">
        <PaymentCombo data={courseData} />
        {/* <RefundBanner /> */}
        <img src="/img/bg/whatsappbanner.jpg" className="my-5" style={{
          borderRadius: 15,
          cursor: "pointer",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
        }} onClick={() => {
          window.open("https://chat.whatsapp.com/JIc1WVgSMxA4Kg2Fxx5VqZ", "_blank")
        }} />
      </div>
      {/* {!isCurrentCoursePurchased && (
        <div className="container my-5">
          <HeadingNew title={"Frequently Asked Questions"} small start />
          <SilvaLifeFAQ />
        </div>
      )} */}
      <div className="container">
        <HeadingNew title={"Frequently Asked Questions About Silva Method Combo Course"} small start />
        <SilvaComboFAQ />
      </div>
      <ContactSection />
      <ComboFooter />
      {/* <SaleModal show={show} onHide={onHide} handleShow={handleShow}/> */}
    </div>
  );
}

export default ComboCourse;
