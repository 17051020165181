import React, { useContext, useEffect, useState } from 'react'
import { Button, Row, Col, Image } from 'react-bootstrap';
import { AiOutlineCalendar, AiFillCheckCircle } from "react-icons/ai";
import "./Bookings.css"
import { GiStairsGoal } from "react-icons/gi"
import { RiMentalHealthLine } from "react-icons/ri";
import { TbYoga } from "react-icons/tb";
import { BiBrain } from "react-icons/bi"
import { FaUsers } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import HeadingNew2 from './HeadingNew2';
import { AuthContext } from '../../context/AllContext';
import BookCard from './BookCard';
import TeachersCard from './TeachersCard';
import WeeksCard from './WeeksCard';
import TestimonialCard from './TestimonialCard';
import OutComeCard from './OutComeCard';
import JoinCard from './JoinCard';
import WhatYouGet from './WhatYouGet';
import ProgramCard from './ProgramsCard';
import FAQ22 from './FAQ22';
import StickyFooter from './StickyFooter';
import BookingModal from './BookingModal';
import LearnCard from './LearnCard';
import SecondHeroSection from './SecondHeroSection';
import ParaComp from '../SilvaManifestationProgram/ParaComp';
import HeadingNew from '../AboutUs/HeadingNew';
import forbes from "../../images/forbes.png";
import nytimes from "../../images/nytimes.png";
import usatoday from "../../images/usatoday.png";
import washington from "../../images/washingtonpost.png"
import ThankYouModal from './ThankYouModal';

function Booking() {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1440px)' });
    const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
    const { isMobile } = useContext(AuthContext);
    const [playing, setPlaying] = useState([
        false, false, false, false, false
    ]);



    const togglePlaying = (index) => {
        const played = [...playing];
        played[index] = !played[index]
        setPlaying(played);


    };


    const [testi, setTesti] = useState([])

    const getreviews = async () => {
        const myHeaders = new Headers();
        myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
        myHeaders.append("Device", "Web");
        myHeaders.append("Language", "english");

        const formData = new FormData();
        formData.append("start_index", "0");
        formData.append("no_of_records", "1000");
        const country = localStorage.getItem("countryname");
        const currency = localStorage.getItem("currency")


        if (country) {
            myHeaders.append("country", country.toString())
        }

        if (currency) {
            myHeaders.append("currency", currency.toString())
        }
        const options = {
            method: 'POST',
            headers: myHeaders,
            body: formData
        }
        const res = await fetch(`https://silvamethod.com/manage/api/testimonialList`, options)
        const data = await res.json();
        //console.log(data.data.classes);
        setTesti(data.data);
    }

    useEffect(() => {
        getreviews()
    }, []);
    const [show, setShow] = useState(false);


    const onHide = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const customButtonStyle = {
        background: '#f1c40f', // Charcoal background color
        border: '1px solid whitesmoke', // White smoke border
        color: 'black', // White smoke text color
        marginRight: '10px', // Add spacing between buttons,
        borderRadius: "8px"
    };

    const buttonStyle = {
        background: '#f1c40f',
        border: '1px solid black', // Black border
        color: 'black', // Text color,
        padding: "8px 30px",
        fontWeight: "600",
        borderRadius: "10px",
        border: '1px solid whitesmoke', // White smoke border


    };


    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        cssEase: "linear",
        centerMode: false,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };

    const settings2 = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        cssEase: "linear",
        centerMode: false,
        prevArrow: <GrFormPrevious />,
        nextArrow: <GrFormNext />,
    };


    const brandImages = [
        forbes, nytimes, usatoday, washington
    ]


    const videoData = [
        {
            id: 1,
            title: 'Video 1',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/7a9ae071-8b4c-47f0-8a16-2a64bb754e0c/playlist.m3u8',
            poster: "/img/posters/thumbnail-1.jpg"
        },
        {
            id: 2,
            title: 'Video 2',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/12e82b7f-37d5-486c-8e28-ebd8ed9f10b5/playlist.m3u8',
            poster: "/img/posters/thumbnail-2.jpg"
        },
        {
            id: 3,
            title: 'Video 3',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/204c0a01-bada-4eaa-997e-9374b392bff3/playlist.m3u8',
            poster: "/img/posters/thumbnail-3.jpg"
        },
        {
            id: 6,
            title: 'Video 6',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/49d4956b-cdd9-4b8f-9be0-697a8fa4e147/playlist.m3u8',
            poster: "/img/posters/thumbnail-6.jpg"
        },
        {
            id: 4,
            title: 'Video 4',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/0a95a889-4107-4a3b-88e3-235320ca290a/playlist.m3u8',
            poster: "/img/posters/thumbnail-4.jpg"
        },
        {
            id: 5,
            title: 'Video 5',
            url: 'https://vz-47e4ab86-ce6.b-cdn.net/bea4479d-9b56-45a5-93f3-8fa54b192c2d/playlist.m3u8',
            poster: "/img/posters/thumbnail-5.jpg"
        },
    ];


    const [show2, setShow2] = useState(true);

    const onHide2 = () => {
        setShow2(false);
    }



    return (

        <div>
            <div style={{
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: isMobile ? "0px" : "30px"
                
            }}>


                <div className='herosection d-flex justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center flex-column herosectionwidth'>

                        <div className='d-flex justify-content-center align-items-center gap-4 mb-4'>
                            <Image
                                src={require("../../images/newimgs/silvamethod-logo.png")}
                                width={250}
                                height={40}
                            />

                        </div>

                        <div className='mt-1 mb-3'>
                            <HeadingNew2
                                title={"Elevate Yourself to the Top 10 % of Successful People"}
                                white

                            />
                        </div>
                        <ParaComp
                            data={[
                                "Accelerate Your Mind's Potential with Proven Silva Method Training."
                            ]}
                            white
                            center
                            margin={false}
                        />

                        <div style={{
                            alignSelf: "center",
                            marginTop: "10px"
                        }}>
                            <Button style={buttonStyle} variant="primary" onClick={handleShow}>
                                <AiFillCheckCircle color='black' size={30} /> Enroll Now for Free
                            </Button>

                        </div>


                        <div className='mt-3'>
                            <ParaComp
                                data={[
                                    "Meet our certified Silva Method Instructors"
                                ]}
                                white
                                center
                                margin={false}
                            />
                        </div>


                        <div className='herosectionwidth2'>
                            <div className='d-flex justify-content-center mt-1 mb-4 flex-wrap gap-3'>

                                <div className='imgbackground'>


                                    <div class="image-container">
                                        <Image
                                            src={"img/programs/teachers/debayan.jpg"}
                                            alt="Debayan"
                                            width="0"

                                            height="0"
                                            sizes="100vw"

                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }

                                        />
                                        <div class="overlay">Debayan</div>
                                    </div>


                                </div>
                                <div className='imgbackground'>
                                    <div class="image-container">
                                        <Image
                                            src={"img/programs/teachers/debalina.jpg"}

                                            // width="0"

                                            // height="0"
                                            // sizes="100vw"
                                            alt=""
                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }
                                        />
                                        <div class="overlay">Debalina</div>
                                    </div>

                                </div>

                                <div className='imgbackground'>
                                    <div class="image-container">
                                        <Image
                                            src={"img/abdul.png"}
                                            // width="0"

                                            // height="0"
                                            // sizes="100vw"
                                            alt=""
                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }

                                        />
                                        <div class="overlay">Abul Kalam</div>
                                    </div>
                                </div>
                                <div className='imgbackground'>
                                    <div class="image-container">
                                        <Image
                                            src={"img/programs/teachers/soumyajit.jpg"}

                                            // width="0"

                                            // height="0"
                                            // sizes="100vw"
                                            alt=""
                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }
                                        />
                                        <div class="overlay">Soumyajit</div>
                                    </div>

                                </div>
                                <div className='imgbackground'>
                                    <div class="image-container">
                                        <Image
                                            src={"img/programs/teachers/ananya.jpg"}
                                            // width="0"

                                            // height="0"
                                            // sizes="100vw"
                                            alt=""
                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }

                                        />
                                        <div class="overlay">Ananya</div>
                                    </div>
                                </div>
                                <div className='imgbackground'>
                                    <div class="image-container">
                                        <Image
                                            src={"img/programs/teachers/debashis.jpg"}
                                            // width="0"

                                            // height="0"
                                            // sizes="100vw"
                                            alt=""
                                            className="w-95 h-auto contactbanner"
                                            style={
                                                { width: "98%", borderRadius: "10px", height: "auto" }
                                            }

                                        />
                                        <div class="overlay">Debashis</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='d-flex justify-content-center align-items-center gap-2 flex-wrap'>
                            <Button variant="outline-warning" className="no-hover-effect-button" >
                                <span className='me-2'>
                                    <AiOutlineCalendar color='#f1c40f' size={25} />
                                </span>
                                Bengali Class on 27th January
                            </Button>
                            <Button variant="outline-warning" className="no-hover-effect-button" >
                                <span className='me-2'>
                                    <AiOutlineCalendar color='#f1c40f' size={25} />
                                </span>
                                English & Hindi Class on 3rd February
                            </Button>
                        </div>
{/* 
                        <div className='d-flex justify-content-center align-items-center gap-2 flex-wrap mt-2'>
                         
                        </div> */}

                        <ParaComp
                            center
                            data={[
                                "Hurry Up! Don’t miss this chance to get a free guided meditation exercise."
                            ]}
                        />

                    </div>


                </div>
                <div className="container mt-2">
                    <HeadingNew2
                        white
                        title={"Silva Method has been Featured on"}
                    />
                    <div className='d-flex justify-content-center flex-wrap gap-3 my-4'>
                        {
                            brandImages.map((im, i) => (
                                <div key={i} style={{
                                    padding: "10px 15px",
                                    background: "whitesmoke",
                                    borderRadius: "8px"
                                }}>
                                    <Image
                                        width={100}
                                        style={{
                                            objectFit: "contain"
                                        }}
                                        height={40}
                                        src={im}
                                    />

                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
            <div style={{
                background: "black"

            }}
                className='py-4'
            >
                <div className="container">
                    <HeadingNew2
                        title={"Who is this program for?"}
                        white
                    />

                    {
                        (isDesktopOrLaptop || isSmallDesktop) && (
                            <div style={{ width: "100%" }}>
                                <Slider {...settings} className='programfor'>
                                    <div className='d-flex justify-content-center my-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard title={"Stress & Anxiety Reduction"}
                                            img={"/img/programs/Stress-&-Anxiety-Reduction.jpg"}
                                            text={"The Silva Method includes relaxation and meditation techniques that help reduce stress, promote relaxation, and increase a sense of calm."}
                                        />
                                        <BookCard title={"Improved Creativity & Concentration"}
                                            text={"Silva Method helps unlock one’s creative potential and stimulates their concentration power."}
                                            img={"/img/programs/Improved-Creativity-&-Concentration.jpg"}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center my-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard
                                            title={"Enhanced Problem-Solving"}
                                            text={"This program provides tools for more effective decision-making and problem-solving."}
                                            img={"/img/programs/Enhanced-Problem-Solving.jpg"}
                                        />
                                        <BookCard
                                            title={"Greater Intuition"}
                                            text={"Silva Method always emphasizes the development of intuitive abilities and greater self-awareness."}
                                            img={"/img/programs/Greater-Intuition.jpg"}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center my-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard
                                            title={"Self-Healing and Well-Being"}
                                            text={"People attribute improved health and well-being, such as better sleep patterns, to their practice of the Silva Method."}
                                            img={"/img/programs/Self-Healing-and-Well-Being.jpg"}
                                        />
                                        <BookCard
                                            title={"Better Relationships"}
                                            text={"Enhanced communication and empathy skills developed through the method will lead to improved relationships with others."}
                                            img={"/img/programs/Better-Relationships.jpg"}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center my-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard
                                            title={"Goal Achievement"}
                                            text={"Silva Method techniques are often used to set and achieve personal and professional goals."}
                                            img={"/img/programs/Goal-Achievement.jpg"}
                                        />
                                        <BookCard
                                            title={"Improved Memory & Learning"}
                                            text={"The method may provide techniques for better memory recall and retention."}
                                            img={"/img/programs/Improved-Memory-&-Learning.jpg"}

                                        />
                                    </div>
                                    <div className='d-flex justify-content-center my-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard title={"Reduced Negative Habits"}
                                            text={"This method assists in breaking negative habits and adopting healthier ones."}
                                            img={"/img/programs/Reduced-Negative-Habits.jpg"}
                                        />
                                        <BookCard
                                            text={"Better Time Management"}
                                            title={"It can help individuals become more organized and efficient in managing their time and tasks"}
                                            img={"/img/programs/Better-Time-Management.jpg"}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center mt-5 mx-3 gap-4 flex-wrap'>
                                        <BookCard title={"Spiritual Growth"}
                                            text={"The Silva method supports one’s spiritual or personal growth journey."}
                                            img={"/img/programs/Spiritual-Growth.jpg"}
                                        />
                                        <BookCard
                                            title={"Improved Quality of Life"}
                                            text={"For active practitioners, the Silva Method contributes to an improved overall quality of life, both in terms of mental and physical well-being."}
                                            img={"/img/programs/Improved-Quality-of-Life.jpg"}
                                        />
                                    </div>

                                </Slider>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: "100000000",
                                    marginBottom: "15px"
                                }}>
                                    <Button style={buttonStyle} variant="primary" onClick={handleShow}>
                                        <AiFillCheckCircle color='black' size={30} /> Sign Up Now
                                    </Button>

                                </div>

                            </div>
                        )
                    }

                    {
                        (isTablet || isMobile) && (
                            <div style={{ width: "100%" }}>
                                <Slider {...settings2} className='programfor'>
                                    <BookCard title={"Stress & Anxiety Reduction"}
                                        img={"/img/programs/Stress-&-Anxiety-Reduction.jpg"}
                                        text={"The Silva Method includes relaxation and meditation techniques that help reduce stress, promote relaxation, and increase a sense of calm."}
                                    />
                                    <BookCard title={"Improved Creativity & Concentration"}
                                        text={"Silva Method helps unlock one’s creative potential and stimulates their concentration power."}
                                        img={"/img/programs/Improved-Creativity-&-Concentration.jpg"}
                                    />
                                    <BookCard
                                        title={"Enhanced Problem-Solving"}
                                        text={"This program provides tools for more effective decision-making and problem-solving."}
                                        img={"/img/programs/Enhanced-Problem-Solving.jpg"}
                                    />
                                    <BookCard
                                        title={"Greater Intuition"}
                                        text={"Silva Method always emphasizes the development of intuitive abilities and greater self-awareness."}
                                        img={"/img/programs/Greater-Intuition.jpg"}
                                    />
                                    <BookCard
                                        title={"Self-Healing and Well-Being"}
                                        text={"People attribute improved health and well-being, such as better sleep patterns, to their practice of the Silva Method."}
                                        img={"/img/programs/Self-Healing-and-Well-Being.jpg"}
                                    />
                                    <BookCard
                                        title={"Better Relationships"}
                                        text={"Enhanced communication and empathy skills developed through the method will lead to improved relationships with others."}
                                        img={"/img/programs/Better-Relationships.jpg"}
                                    />
                                    <BookCard
                                        title={"Goal Achievement"}
                                        text={"Silva Method techniques are often used to set and achieve personal and professional goals."}
                                        img={"/img/programs/Goal-Achievement.jpg"}
                                    />
                                    <BookCard
                                        title={"Improved Memory & Learning"}
                                        text={"The method may provide techniques for better memory recall and retention."}
                                        img={"/img/programs/Improved-Memory-&-Learning.jpg"}

                                    />
                                    <BookCard title={"Reduced Negative Habits"}
                                        text={"This method assists in breaking negative habits and adopting healthier ones."}
                                        img={"/img/programs/Reduced-Negative-Habits.jpg"}
                                    />
                                    <BookCard
                                        text={"Better Time Management"}
                                        title={"It can help individuals become more organized and efficient in managing their time and tasks"}
                                        img={"/img/programs/Better-Time-Management.jpg"}
                                    />
                                    <BookCard title={"Spiritual Growth"}
                                        text={"The Silva method supports one’s spiritual or personal growth journey."}
                                        img={"/img/programs/Spiritual-Growth.jpg"}
                                    />
                                    <BookCard
                                        title={"Improved Quality of Life"}
                                        text={"For active practitioners, the Silva Method contributes to an improved overall quality of life, both in terms of mental and physical well-being."}
                                        img={"/img/programs/Improved-Quality-of-Life.jpg"}
                                    />

                                </Slider>

                            </div>
                        )
                    }
                    <div className="mt-5">
                        <HeadingNew2
                            title={"Tools you will learn"}
                            white
                        />
                    </div>

                    <Row className='justify-content-center align-items-start mt-4'>

                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Headache-control.jpg"} text={"Alleviating headaches and migraines naturally without medication"} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Sleep-and-dream-control.jpg"} text={"Sleep naturally & recall your dreams for problem-solving"} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Memory-Pegs.jpg"} text={"Learn to project and develop your visualization and imagination abilities."} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Glove-anesthesia.jpg"} text={"Gain control over pain and reduce bleeding and hemorrhaging."} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/ESP.jpg"} text={"Enhance your ESP abilities - clairvoyance, telepathy, and precognition."} />
                        </Col>

                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Developing-Intuition.jpg"} text={"Quiet your mind and connect with universal energy for insights."} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Connection-to-purpose.jpg"} text={"Find the sole purpose of your life & experience contentment."} />
                        </Col>
                        <Col xs={6} md={6} lg={4}>
                            <LearnCard src={"/img/programs/Healing.jpg"} text={"Heal naturally from any physical pain and life-threatening diseases."} />
                        </Col>
                    </Row>
                    {/* <div className='container d-flex justify-content-center my-5 gap-5 flex-wrap'>
                       
                    </div> */}
                    {/* <h5 className='white-color text-center'>And many more...</h5> */}

                </div>
            </div>

            <div style={{
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}
                className='py-5'
            >


                <HeadingNew2
                    title={"Course Content and Mental exercises curated by"}
                    white

                />


                <TeachersCard
                    data={["Meet Jose Silva, the visionary behind the Silva Method, a groundbreaking approach to meditation that reshaped the way we harness the power of our minds. Driven by his passion and the academic struggles of his children, Jose dedicated over two decades to research and innovation. What emerged were unique Silva Method exercises that not only boosted academic performance but also transformed countless lives.",
                        "Jose's mission was simple yet profound: to supercharge human brains and empower minds to flourish in every aspect of life. In the 1960s, his work garnered widespread attention, making The Silva Method courses a sensation in New York. Soon, professionals from various fields, including businessmen, stockbrokers, salespeople, and managers, recognized the potential of these courses for personal growth. Today, Silva's legacy lives on through Silva International, an organization steadfast in continuing its mission."
                    ]}
                    img={require("../../img/shape/jose_silva.jpg")}
                />

                <TeachersCard
                    data={["Diana Silva, our very own Silva Life Coach and the youngest of Jose Silva's children. She's here to guide and support students and seekers on their Silva Method journey. Growing up immersed in Silva, Diana has dedicated her life to personal growth and empowering the mind.",
                        "As a certified Silva Coach, Diana combines her natural insight with effective coaching tools and the proven Silva Method Techniques to help you bridge the gap between where you are now and where you want to be. With over 30 years of experience, Diana has been a pillar of support for Silva Graduates and the global community of Silva Instructors. Her approach marries the mental techniques of the Silva Method with cutting-edge coaching methods, propelling her clients toward an elevated way of life. Diana isn't just a coach; she's a mentor and a friend to Silva Graduates worldwide."
                    ]}
                    img={require("../../images/about/dsilva (1).jpg")}

                />
        
            </div>
            <div style={{
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>

                <div className="container my-4">
                    <div className='mb-4'>
                        <HeadingNew2
                            title={"What will you learn?"}
                            white
                        />
                    </div>
                    {
                        (!isTablet && !isMobile) && (
                            <Row className='justify-content-center g-1 my-5 mx-2'>
                                <Col md={6}>
                                    <WeeksCard title={"History of Silva Method"}
                                        text={"Explore the origins and evolution of the Silva Method."}
                                        number={1}
                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Jose Silva"}
                                        text={"Learn about the founder and visionary behind this transformative approach."}
                                        number={2}
                                    />
                                </Col>

                                <Col md={6}>
                                    <WeeksCard
                                        title={"Brain & Mind Relationship"}
                                        text={"Understand the critical connection between your brain and mind."}
                                        number={3}
                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Achieving Goals"}
                                        text={"Discover the tools and techniques to manifest your goals effectively."}
                                        number={4}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Brain Parts"}
                                        text={"Explore the different parts of your brain and their roles in your daily life."}
                                        number={5}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard title={"Brainwave Frequencies"}
                                        text={"Uncover the four key brainwave states – Beta, Alpha, Theta, and Delta."}
                                        number={6}

                                    />
                                </Col>

                                <Col md={6}>
                                    <WeeksCard
                                        title={"Brainwave Explanation"}
                                        text={"Understand what Beta, Alpha, Theta, and Delta states mean for your mental state."}
                                        number={7}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Balancing Your Brain"}
                                        text={"Learn how to maintain a balanced and harmonious brain function."}
                                        number={8}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Thoughts Defined"}
                                        text={"Explore the nature of thoughts and their impact on your life."}
                                        number={9}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Staying in Alpha"}
                                        text={"Master the art of staying in the Alpha state of mind for enhanced awareness."}
                                        number={10}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Alpha Benefits"}
                                        text={"Discover the advantages of operating in the Alpha mindstate."}
                                        number={11}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Physical Health"}
                                        text={"Learn how the Silva Method can contribute to your physical well-being."}
                                        number={12}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Mental Health"}
                                        text={"Explore the ways the Silva Method promotes mental health and well-being."}
                                        number={13}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Social Health"}
                                        text={"Understand how the Silva Method can improve your social connections."}
                                        number={14}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Education"}
                                        text={"See how the Silva Method aids in learning and education."}
                                        number={15}
                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Wealth"}
                                        text={"Learn how the Silva Method can lead to financial abundance."}
                                        number={16}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Relationships"}
                                        text={"Enhance your relationships with the Silva Method's techniques."}
                                        number={17}

                                    />
                                </Col>
                                <Col md={6}>
                                    <WeeksCard
                                        title={"Spirituality"}
                                        text={"Unlock spiritual growth and fulfillment through the Silva Method."}
                                        number={18}

                                    />
                                </Col>


                            </Row>
                        )
                    }

                    {
                        (isTablet || isMobile) && (
                            <div style={{ width: "100%" }}>
                                <Slider {...settings2} className='programfor'>
                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard title={"History of Silva Method"}
                                            text={"Explore the origins and evolution of the Silva Method."}
                                            number={1}
                                        />
                                        <WeeksCard
                                            title={"Jose Silva"}
                                            text={"Learn about the founder and visionary behind this transformative approach."}
                                            number={2}
                                        />
                                    </div>


                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Brain & Mind Relationship"}
                                            text={"Understand the critical connection between your brain and mind."}
                                            number={3}
                                        />
                                        <WeeksCard
                                            title={"Achieving Goals"}
                                            text={"Discover the tools and techniques to manifest your goals effectively."}
                                            number={4}
                                        />
                                    </div>

                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Brain Parts"}
                                            text={"Explore the different parts of your brain and their roles in your daily life."}
                                            number={5}

                                        />
                                        <WeeksCard title={"Brainwave Frequencies"}
                                            text={"Uncover the four key brainwave states – Beta, Alpha, Theta, and Delta."}
                                            number={6}

                                        />
                                    </div>

                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Brainwave Explanation"}
                                            text={"Understand what Beta, Alpha, Theta, and Delta states mean for your mental state."}
                                            number={7}

                                        />
                                        <WeeksCard
                                            title={"Balancing Your Brain"}
                                            text={"Learn how to maintain a balanced and harmonious brain function."}
                                            number={8}

                                        />
                                    </div>


                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Thoughts Defined"}
                                            text={"Explore the nature of thoughts and their impact on your life."}
                                            number={9}

                                        />

                                        <WeeksCard
                                            title={"Staying in Alpha"}
                                            text={"Master the art of staying in the Alpha state of mind for enhanced awareness."}
                                            number={10}

                                        />

                                    </div>

                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Alpha Benefits"}
                                            text={"Discover the advantages of operating in the Alpha mindstate."}
                                            number={11}

                                        />
                                        <WeeksCard
                                            title={"Physical Health"}
                                            text={"Learn how the Silva Method can contribute to your physical well-being."}
                                            number={12}

                                        />
                                    </div>

                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Mental Health"}
                                            text={"Explore the ways the Silva Method promotes mental health and well-being."}
                                            number={13}

                                        />
                                        <WeeksCard
                                            title={"Social Health"}
                                            text={"Understand how the Silva Method can improve your social connections."}
                                            number={14}

                                        />
                                    </div>
                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Education"}
                                            text={"See how the Silva Method aids in learning and education."}
                                            number={15}
                                        />
                                        <WeeksCard
                                            title={"Wealth"}
                                            text={"Learn how the Silva Method can lead to financial abundance."}
                                            number={16}

                                        />
                                    </div>

                                    <div className='d-flex justify-content-center flex-wrap'>
                                        <WeeksCard
                                            title={"Relationships"}
                                            text={"Enhance your relationships with the Silva Method's techniques."}
                                            number={17}

                                        />
                                        <WeeksCard
                                            title={"Spirituality"}
                                            text={"Unlock spiritual growth and fulfillment through the Silva Method."}
                                            number={18}

                                        />
                                    </div>





                                </Slider>

                            </div>
                        )}

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "100000000",
                        marginBottom: "15px"
                    }}
                        className='mt-3'
                    >
                        <Button style={buttonStyle} variant="primary" onClick={handleShow}>
                            <AiFillCheckCircle color='black' size={30} /> Tap to Join for Free
                        </Button>

                    </div>

                </div>





                <div className="container my-4">
                    <HeadingNew2
                        title={"What Others Say about The Silva Method"}
                        white
                    />

                    {
                        (isTablet || isMobile) && (
                            <div style={{ width: "100%" }} className='mt-3'>
                                <Slider {...settings2} className='programfor'>
                                    {
                                        testi.map((val, i) => (
                                            <TestimonialCard data={val} />
                                        ))
                                    }
                                </Slider>
                            </div>
                        )}
                    {
                        (!isTablet && !isMobile) && (
                            <Row className='justify-content-center g-2 my-4'>
                                {
                                    testi.map((val, i) => (
                                        <Col md={6}>
                                            <TestimonialCard data={val} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        )
                    }



                </div>

                <div className="container my-4">
                    <div className='mb-3'>
                        <HeadingNew2
                            title={"Video Testimonials"}
                            white
                        />
                    </div>
                    <Row className='justify-content-center align-items-center' style={{
                        margin: "auto"
                    }}>

                        {videoData.map((video, index) => (
                            <Col xs={6} md={4} lg={4} key={video.id}>
                                <div className="video-card w-100 mb-4" onClick={() => togglePlaying(index)}>
                                    <ReactPlayer
                                        url={video.url}
                                        width={"93%"}
                                        playing={playing[index]}
                                        controls
                                        light={video.poster}

                                    // playing={playing}
                                    // onPlay={() => setShowPoster(false)}
                                    />
                                </div>
                            </Col>
                        ))}

                    </Row>
                </div>

                <div className="container my-4">
                    <HeadingNew2
                        title={"Outcomes you can expect"}
                        white
                    />
                    <Row className='justify-content-center g-3 mt-2'>
                        <Col md={6}>
                            <OutComeCard title={"Goal-Oriented Mindset "}
                                data={[
                                    "Learn to stay focused and motivated",
                                    "Develop a growth mindset to overcome any obstacles",
                                    "A roadmap to turn your dreams into reality"
                                ]}
                            />
                        </Col>
                        <Col md={6}>
                            <OutComeCard title={"Career Advancement"}
                                icon={<GiStairsGoal color='white' size={40} />}
                                data={[
                                    "Optimize Mental faculties & boost creativity",
                                    "Adapt to challenging situations with calmness",
                                    "Strengthen your decision-making ability"
                                ]}
                                icon2={<GiStairsGoal color='white' size={150} />}
                            />
                        </Col>
                        <Col md={6}>
                            <OutComeCard
                                title={"Nurture Positive Relationships"}
                                icon={<FaUsers color='white' size={40} />}
                                data={[
                                    "Develop better communication skills",
                                    "Get Liberated from ungrained habits",
                                    "Experience harmony and a deeper sense of connection"
                                ]}
                                icon2={<FaUsers color='white' size={150} />}
                            />
                        </Col>
                        <Col md={6}>
                            <OutComeCard
                                icon={<BiBrain color='white' size={40} />}
                                title={"Enhance Memory and Concentration"}
                                data={[
                                    "Get a super sharp memory and focusing ability",
                                    "Learn the ability to find lost items",
                                    "Better equipped to tackle intellectual challenges and tasks."
                                ]}
                                icon2={<BiBrain color='white' size={150} />}

                            />
                        </Col>
                        <Col md={6}>
                            <OutComeCard
                                icon={<TbYoga color='white' size={40} />}
                                title={"Attain Inner Serenity & Self-Confidence"}
                                data={[
                                    "Reduce stress and anxiety and feel relaxed",
                                    "Gain a sense of calm, emotional balance, and resilience",
                                    "Boosts your Confidence by nurturing your inner state"
                                ]}
                                icon2={<TbYoga color='white' size={150} />}

                            />
                        </Col>
                        <Col md={6}>
                            <OutComeCard
                                icon={<RiMentalHealthLine color='white' size={40} />}
                                title={"Healthy Lifestyle and Wellbeing"}
                                data={[
                                    "Helps you break free from any painful addictions",
                                    "Channelize your body’s natural healing abilities",
                                    "Fosters healthy habits for your wellbeing"
                                ]}
                                icon2={<RiMentalHealthLine color='white' size={150} />}

                            />
                        </Col>

                    </Row>
                </div>

                <div className="container my-4">
                    <HeadingNew2
                        title={"Program Details"}
                        white
                    />

                    <Row className='my-4 justify-content-center gap-5 align-items-center' style={{
                        margin: isMobile ? "0 10px" : "0"
                    }}>
                        <Col xs={12} md={5}>
                            <JoinCard handleShow={handleShow} />
                        </Col>
                        <Col md={6}>
                            <WhatYouGet />
                        </Col>
                    </Row>


                </div>

                <div className="container my-4">
                    <div className='mb-4'>
                        <HeadingNew
                            title={"How does this program work?"}
                            white
                        />
                    </div>
                    <Row className='justify-content-center gap-4 mx-2'>
                        <Col sm={12} md={8}>
                            <ProgramCard title={"Doubt Clearing and Healing Sessions"}
                                img={"/img/programs/Doubt-Clearing-and-Healing-Sessions.jpg"} />
                        </Col>
                        <Col sm={12} md={8}>
                            <ProgramCard reversed={true} title={"Weekly meetups for Silva Graduates"}
                                img={"/img/programs/Weekly-meetups-for-Silva-Graduates.jpg"}
                            />
                        </Col>
                        <Col sm={12} md={8}>
                            <ProgramCard title={"Lifetime Access with Minimal Charges"}
                                img={"/img/programs/Lifetime-Access-with-Minimal-Charges.jpg"}
                            />
                        </Col>
                        {/* <Col sm={12} md={8}>
                            <ProgramCard reversed={true} title={"Instant Money Back. No Questions Asked"}
                                img={"/programs/Instant-Money-Back.-No-Questions-Back.jpg"}
                            />
                        </Col> */}
                        <Col sm={12} md={8}>
                            <ProgramCard title={"Active WhatsApp group with Community Access"}
                                img={"/img/programs/Active-WhatsApp-group-with-Community-Access.jpg"}
                                reversed={true}

                            />
                        </Col>
                    </Row>
                </div>

                <div className="container my-4 d-flex justify-content-center align-items-center flex-column">
                    <HeadingNew2
                        title={"Certification Awaiting!"}
                        white
                    />
                    <div className='mt-4'>
                        <HeadingNew2
                            white
                            small
                            title={"You are worth it! You will become a certified Silva Graduate on the completion of the full Silva Life and Intuition System, starting from 2nd December."}
                        />
                    </div>
                    <Image
                        src={"/img/programs/certificate.jpg"}
                        width={300}
                        height={400}
                        className="mt-4"
                    // height={350}

                    // height="0"
                    // sizes="100vw"
                    // alt=""
                    // className="contactbanner mobilehide mt-4"
                    // style={
                    //     {borderRadius: "10px", height: "auto" }
                    // }

                    />

                    <div className='mt-5'>
                        <HeadingNew2
                            title={"Why Silva Method?"}
                            white
                        />
                    </div>

                    <div className='mt-4'>
                        <ParaComp
                            data={[
                                "The Silva Method stands out among all meditation programs by offering a unique practice of tapping into a higher intelligence source. Through this, anyone can receive guidance from the universe, cosmos, or guardian angels, whatever you may call it. This not only empowers you to make wise decisions but also equips you with the ability to anticipate and navigate any challenges that come your way. It's a remarkable experience that's exclusive to the Silva Method!"
                            ]}
                            margin={false}
                            white
                            center
                        />

                    </div>


                </div>
                <div className='mt-4'>
                    <SecondHeroSection />
                </div>
                <div className="container" style={{
                    marginBottom: "100px"
                }}>
                    <HeadingNew2
                        title={"FAQs: Here’s everything you may ask..."}
                        white
                    />

                    <div className='mt-4'>
                        <FAQ22
                            heading={"Is this webinar live or recorded?"}
                            body={<p style={{ color: "white" }}>Silva's introductory webinar is a live webinar. This provides attendees with the opportunity to engage in live interactions, ask questions, and receive immediate responses from the presenters, creating an interactive and dynamic learning experience.</p>}
                        />
                        <FAQ22
                            heading={"Can anyone learn the Silva Method, or is it only for certain people?"}
                            body={<p style={{ color: "white" }}>
                                Anyone can learn the Silva Method, regardless of age, background, or experience level. The program is designed to be accessible to everyone.
                            </p>}
                        />

                        {/* <FAQ22
                            heading={"What is our money-back guarantee?"}
                            body={<p style={{ color: "white" }}>
                                If you take the course and feel you haven't fully experienced its life-changing benefits, we will promptly issue a refund - no questions asked. Refund requests must be made by the end of the last day of the course.
                            </p>}
                        /> */}
                        <FAQ22
                            heading={"Do I need to pay for the introductory webinar?"}
                            body={<p style={{ color: "white" }}>
                                No, the introductory webinar is completely free and open to everyone. Join us at no cost.
                            </p>}
                        />
                        <FAQ22
                            heading={"What we will learn in this introductory webinar?"}
                            body={<div>
                                <p style={{
                                    fontSize: "1.2rem",
                                    fontWeight: "600"
                                }} className='text-white'>Agenda of Introductory Seminar :</p>
                                <ul style={{
                                    marginLeft: "15px"
                                }}>
                                    <li>History Of the Silva Method </li>
                                    <li>Jose Silva
                                        Brain & Mind relation</li>
                                    <li>
                                        what we need to achieve our goals
                                    </li>
                                    <li>
                                        Parts of our brain
                                    </li>
                                    <li>
                                        brainwave frequencies: Beta, Alpha, Theta, Delta
                                    </li>

                                    <li>
                                        Explanation of Beta, Alpha, Theta, and Delta brainwave states
                                    </li>

                                    <li>
                                        How to balance your brain
                                    </li>
                                    <li>
                                        What are thoughts
                                    </li>
                                    <li>
                                        How to stay in Alpha level
                                    </li>
                                    <li>
                                        Alpha level advantages
                                    </li>
                                    <li>
                                        How the Silva Method Can Help in Physical Health
                                    </li>
                                    <li>
                                        How the Silva Method can help in Mental Health
                                    </li>
                                    <li>
                                        How the Silva Method Can Help in Social Health
                                    </li>
                                    <li>
                                        How the Silva Method Can Help in Education
                                    </li>
                                    <li>
                                        How the Silva Method Can Help in Wealth
                                    </li>
                                    <li>
                                        How the Silva Method Can Help in Relationship
                                    </li>
                                    <li>
                                        How the Silva Method can help in Spirituality
                                    </li>

                                </ul>
                            </div>}
                        />


                    </div>
                </div>





            </div>


            <StickyFooter handleShow={handleShow} />
            <BookingModal show={show} onHide={onHide} />

            {/* <ThankYouModal show2={show2} onHide2={onHide2} /> */}



        </div>

    )
}

export default Booking