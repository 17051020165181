import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';  // For icons
import { Fade } from 'react-awesome-reveal';     // For animations
import HeadingNew from '../AboutUs/HeadingNew';
import { AuthContext } from '../../context/AllContext';



const BenefitsSectionCourse = ({courseData,isCurrentCoursePurchased}) => {
    const {isMobile} = useContext(AuthContext)

    if(!courseData || (courseData && courseData.course_benefits.length===0)){
        return null
    }
    return (
        <div  style={{ 
            // backgroundColor: '#f8f9fa',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            // height: '90vh',
            width: '100%',
            height:"auto",
            position: "relative",
            backgroundImage:isMobile?"url('https://res.cloudinary.com/dzkwltgyd/image/upload/v1726037413/glif-run-outputs/rk59mbay7krzqkshx3gv.jpg')":"url('https://res.cloudinary.com/dzkwltgyd/image/upload/v1726044690/glif-run-outputs/lipf60jrlm9qy4hqamyv.jpg')"
         }}>
            <div style={{
                width:"100%",
                height:"100%",
                background:"rgba(0,0,0,0.5)"
                // background: "linear-gradient(0deg, #a500ff, #7c00ff)"
            }}
            className="py-5"
            >

           <div className='container'>
           <HeadingNew small title={`Explore the benefits of`} white />
           <HeadingNew title={courseData?.course_title} white />
           
           <Row className='gy-3 mt-4'>
            {
                courseData?.course_benefits.map((val,index)=>(
                    <Col key={index} md={6} lg={4}>
                       <Fade direction="up" cascade damping={index+1} style={{
                        marginLeft:isMobile?"10px":"0px"
                       }}>
                           <div
                               className="d-flex align-items-start p-3 benefitscardcourse"
                           
                           >
                               <span style={{
                                flex:"none"
                               }}>
                               <FaCheckCircle className="text-success me-3" size={28} />
                               </span>
                               <div>
                                   <p style={{ fontSize: '1rem', marginBottom: 0,color:"black",fontWeight:"500" }} className='robotofont'>{val.title}</p>
                               </div>
                           </div>
                       </Fade>
                   </Col>
                ))
            }
            
              
           </Row>
           </div>

           </div>
        </div>
    );
};

export default BenefitsSectionCourse;
