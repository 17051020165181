import React, { useContext, useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthContext } from '../../context/AllContext';
import { requestData } from '../../utils/baseUrl';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';

function CustomNavbar() {
  const { isMobile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const getCourses = async () => {
    setLoading(true);
    const res = await requestData("latestCourseList", "POST", {
      start_index: 0,
      no_of_records: 1000
    });
    setLoading(false);
    if (res && res.error === false) {
      setCourses(res.data.sort((a, b) => Number(a.precedence) - Number(b.precedence)));
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Navbar expand="lg" className="navbarcustomnew container mt-3" sticky="top" style={{
      border: "1px solid rgba(255, 255, 255, 0.2)",
      background: "rgba(255, 255, 255)",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      borderRadius: "30px",
    }}>
      <Container>
        <Navbar.Brand onClick={() => navigate("/")} style={{
          color: "black",
          width: isMobile ? "65%" : "auto"
        }}>
          <Image src='/img/logo/silvamethod-logo.png' style={{
            width: isMobile ? "100%" : "auto"
          }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ border: "none" }} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Fade>
              <NavDropdown
                title="Silva Method Courses"
                id="basic-nav-dropdown"
                style={{
                  background: "transparent",
                }}
                className={`custom-dropdown ${isOpen ? 'show' : ''}`}
                show={isOpen}
                onToggle={(isOpen) => setIsOpen(isOpen)}
              >
                {courses.map((course) => (
                  <NavDropdown.Item
                    key={course.course_id}
                    onClick={() => navigate(`/store/course/${course.course_id}`)}
                  >
                    {course.course_title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Fade>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <style jsx>{`
        .custom-dropdown .dropdown-menu {
          max-height: 80vh;
          overflow-y: auto;
          width: max-content;
          min-width: 200px;
          opacity: 0;
          visibility: hidden;
          transform: translateY(-10px);
          transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
        }
        .custom-dropdown.show .dropdown-menu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
        .custom-dropdown .dropdown-item {
          white-space: normal;
          word-wrap: break-word;
          padding: 10px 15px;
          opacity: 0;
          transform: translateY(-5px);
          transition: opacity 0.2s ease, transform 0.2s ease;
          transition-delay: 0.1s;
        }
        .custom-dropdown.show .dropdown-item {
          opacity: 1;
          transform: translateY(0);
        }
        .navbar-nav .nav-link {
          color: #444;
          font-weight: 700;
          transition: 0.4s all;
        }
        .navbar-nav .nav-link:hover {
          color: #ff3f34;
        }
        .dropdown-menu {
          border: none;
          box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
          border-radius: 3px;
        }
        .dropdown-item:hover {
          background-color: #f8f9fa;
          color: #ff3f34;
        }
        @media (max-width: 991px) {
          .custom-dropdown .dropdown-menu {
            width: 100%;
          }
        }
      `}</style>
    </Navbar>
  );
}

export default CustomNavbar;