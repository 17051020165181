import React, { useContext } from 'react';
import { AuthContext } from '../../context/AllContext';

const HighlightSection = () => {
  const {isMobile} = useContext(AuthContext);

  const highlights = [
    {
      icon: '/img/bg/trophy-cup.svg',
      title: '12M +',
      text: 'Silva Graduates Worldwide'
    },
    {
      icon: '/img/bg/teacher.svg',
      title: '500 +',
      text: 'Instructors'
    },
    {
      icon: '/img/bg/globe.svg',
      title: '120 +',
      text: 'Countries'
    },
    
    {
      icon: '/img/bg/users.svg',
      title: '1 M +',
      text: 'Testimonials'
    }
  ];

  return (
    <section className={isMobile?"highlightSection2 gradientBg py-3":"highlightSection2 gradientBg py-5"}>
      <div className="container">
        <div className={isMobile?"highlightBox d-flex align-items-center gap-3  justify-content-start flex-wrap px-3":"highlightBox d-flex gap-5 align-items-center justify-content-center flex-wrap"}>
          {highlights.map((highlight, index) => (
            <div key={index} className="boxItem d-flex gap-3 align-items-center">
              <div className="iconCont">
                <img src={highlight.icon} alt="#" loading="lazy" width={50} height={50} />
              </div>
              <div className="textCont">
                <h3 className="title primaryWhite fw600 poppins mb-0">
                  {highlight.title}
                </h3>
                <p className="fw500 primaryWhite mb-0">{highlight.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HighlightSection;
