import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { googleLogout } from '@react-oauth/google';
import { toast } from 'react-hot-toast';

export const AuthContext = createContext();

const AllContext = ({children}) => {
    


    const [isUserLoggedIn,setIsUserLoggedIn] = useState(()=>{
        if(localStorage.getItem("token")){
            return true
        }else if(localStorage.getItem("google_login")){
            return true
        }else{
            return false
        }
    });
    const [userData,setUserData] = useState(null);
    const [userToken,setUserToken] = useState("");


      const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1440 })
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
    const isMobile = useMediaQuery({minWidth: 201, maxWidth: 767})
    const isSmallMobile2 = useMediaQuery({minWidth:201, maxWidth: 400})
    const isBigMobile = useMediaQuery({minWidth:401, maxWidth: 767})
    const isTab = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    const isMediumDesktop = useMediaQuery({minWidth: 992, maxWidth: 1024})
    const isNormalDesktop =  useMediaQuery({ minWidth: 1025, maxWidth: 1400 })
      const isTabletOrMobile = useMediaQuery({ query: '(min-width: 200px,max-width: 1224px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const isSmallMobile = useMediaQuery({query:'(max-width:424px)'})
   const isLaptop = useMediaQuery({ minWidth: 992, maxWidth: 1199 })

   const isValidDesktop = useMediaQuery({minWidth: 1024, maxWidth: 1920})



  


    // Slick ArrowLeft
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            <i className="fas fa-arrow-left"></i>
        </button>
    );
    // Slick Arrow Right
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i className="fas fa-arrow-right"></i>
        </button>
    );

    const logout = ()=>{
        const googleLoggedIn = localStorage.getItem("google_login");
        if(googleLoggedIn){
            googleLogout()
            localStorage.removeItem("google_login");
        }
        const token = localStorage.getItem("token");
        if(token){
            localStorage.removeItem("token");
        }
        setUserData(null);
        setIsUserLoggedIn(false)
        toast.success("Logged Out successfully",{
            position:'top-center'
        })
    }

    const [stickyMenu, setStickyMenu] = useState(false);
    // sticky
    useEffect(() => {
        const stickyMenuBar = () => {
            if (window.scrollY > 80) {
                setStickyMenu(true)
            }
            else {
                setStickyMenu(false)
            }
        }
       window.addEventListener('scroll', stickyMenuBar);
    },[])

    const [isOpen, setIsOpen] = useState(false);


    useEffect(()=>{
        if(!localStorage.getItem("token")){
            setUserData(null)
        }

    },[localStorage.getItem("token")])

    const value = {
        isOpen,
        setIsOpen,
        stickyMenu,
        SlickArrowLeft,
        SlickArrowRight,
        isDesktopOrLaptop,
        isBigScreen,
        isTabletOrMobile,
        isTablet,
        isMobile,
        isPortrait,
        isRetina,
        isUserLoggedIn,
        setIsUserLoggedIn,
        userData,
        setUserData,
        logout,
        setUserToken,
        userToken,
        isSmallMobile,
        isMediumDesktop,
        isNormalDesktop,
        isTab,
        isSmallMobile2,
        isBigMobile,
        isLaptop,
        isValidDesktop
    }
    return (
       <>
           <AuthContext.Provider value={value}>
                {children}
           </AuthContext.Provider>
       </>
    );
};

export default AllContext;