import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, ProgressBar, Row } from 'react-bootstrap'
import { requestData4 } from '../../../../utils/baseUrl';
import { useMediaQuery } from 'react-responsive';
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Link } from 'react-router-dom';
import MoonLoader from "react-spinners/MoonLoader";
import LoggedInHeroSection2 from './LoginHeroSection2';
import { AuthContext } from '../../../../context/AllContext';


function SilvaCourseCards() {
    const { userData, isMobile } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [allCourses, setAllCourses] = useState([]);
    const [allCourseDetails, setAllCourseDetails] = useState([]);





    // const fetchAllCourses = async () => {
    //     setLoading(true);

    //     const res = await requestData4("courseListWithChild", "POST", {}, userData.student_id);
    //     setLoading(false);
    //     if (!res) {
    //         return false;
    //     }


    //     const enrolledCourses = [...userData.enrolled_course]

    //     const enrolledCoursesMapped = enrolledCourses.map((val) => val.course_id).filter((el, i) => el.course_id !== 'top-mental-training')



    //     const filteredArr = res.data.filter((val, i) => enrolledCoursesMapped.includes(val.course_id))


    //     let courses = []
    //     for (let course of filteredArr) {
    //         if (course.course_id !== "top-mental-training") {
    //             let allLessonsInCurrentCourse = [];
    //             course.chapters.forEach((chapter, i) => {
    //                 const lessionsInSingleChapter = chapter.lession;
    //                 allLessonsInCurrentCourse = [...allLessonsInCurrentCourse, ...lessionsInSingleChapter]
    //             })
    //             let numofcompleted = 0;
    //             let hasStarted = false;
    //             let isCourseCompleted = true
    //             allLessonsInCurrentCourse.forEach((ls, i) => {
    //                 // console.log(ls,"lslsls",course.course_title)
    //                 if (!ls.lesson_activity_status || ls.lesson_activity_status === "Started") {
    //                     isCourseCompleted = false
    //                 }
    //                 if (ls.lesson_activity_status) {
    //                     hasStarted = true
    //                 }
    //                 if (ls.lesson_activity_status === "Completed" && ls.lesson_activity_duration) {
    //                     // console.log("started")
    //                     hasStarted = true
    //                     ++numofcompleted
    //                 }
    //             })



    //             courses.push({
    //                 courseData: course,
    //                 isCourseStarted: isCourseCompleted ? false : hasStarted,
    //                 isCourseCompleted,
    //                 numofcompleted,
    //                 isCourseCompleted
    //             })
    //         }
    //         // let isCourseStarted=false;
    //         // let isCourseHasNotStarted = false;

    //     }

    //     const coursesNotStarted = courses.filter((cc, i) => cc.isCourseStarted === false).filter((el, i) => el.courseData.course_id !== 'top-mental-training');

    //     const coursesStarted = courses.filter((cc, i) => cc.isCourseStarted === true).filter((el, i) => el.courseData.course_id !== 'top-mental-training');

    //     // const complete = courses.filter((cc,i)=>cc.isCourseCompleted===true)
    //     setStartedCourses(coursesStarted);
    //     setnotStartedCourses(coursesNotStarted);
    //     setAllCourses(courses);
    //     // setCompletedCourses(complete)


    //     // console.log(courses, "courses")
    //     // setAllCourses(res.data);
    // }

    // useEffect(() => {
    //     // setTimeout(()=>{
    //     //     fetchAllCourses()
    //     // },5000)
    //     if (userData) {
    //         fetchAllCourses()
    //     }
    // }, [userData])



    const getAllCourses = async () => {
        setLoading(true);
        const res = await requestData4("latestCourseList", "POST", {
            "start_index": "0",
            "no_of_records": "20"
        });
        setLoading(false);
        const enrolledCourses = [...userData.enrolled_course];
        const enrolledCoursesMapped = enrolledCourses.map((val) => val.course_id).filter((el, i) => el.course_id !== 'top-mental-training');
        const filteredArr = res.data.filter((val, i) => enrolledCoursesMapped.includes(val.course_id) && val.is_combo_course === "No").reverse();
        setAllCourses(filteredArr);
    };


    const getCourseDetails = async (ids) => {

        const promises = ids.map((val) => {
            return requestData4("courseDetail", "POST", {
                course_id: val
            })
        })
        const result = (await Promise.all(promises)).map((val) => val.data[0]);

        // setAllCourseDetails(result)
        const newarr = result.map((val) => {
            let totalNumberOfLessons = 0;
            let totalLessonsCompleted = 0;
            val.chapters.forEach(chapter => {
                // Add the number of lessons in this chapter to the total number of lessons
                totalNumberOfLessons += chapter.lession.length;

                // Count the completed lessons in this chapter and add to the total
                totalLessonsCompleted += chapter.lession.filter(lesson => lesson.lesson_activity_status === "Completed").length;
            })
            val.totalNumberOfLessons = totalNumberOfLessons;
            val.totalLessonsCompleted = totalLessonsCompleted;
            return val

        });
        setAllCourseDetails(newarr);




    };


    useEffect(() => {
        getAllCourses();
    }, [userData])


    useEffect(() => {
        if (allCourses.length) {
            const ids = allCourses.map((val) => {
                return val.course_id
            })
            getCourseDetails(ids)

        }
    }, [allCourses])




    if (loading) {
        return (
            <div style={{ height: "100%" }}>
                <div className='d-flex justify-content-center align-content-center align-items-center'>
                    <MoonLoader
                        color='black'
                        size={150}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={`mb-5 mt-5 container`}>






            {
                (allCourses && allCourses.length >= 1) && (
                    <div
                        className='coursesslider'
                    >
                        <LoggedInHeroSection2 text={"Your Purchased Course/s"} />

                      


                        <div className='productcardsection2'>
                            {
                                allCourses.map((course, i) => (

                                    <Link to={`/store/course/${course?.course_id}/`}>

                                        <Card className='productsinglecard boxshadowclass' key={i} style={{
                                            backgroundImage: `url(${course?.web_vertical_image || "https://silvamethod.com/manage/assets/uploads/noimg.jpg"})`
                                        }}
                                        >
                                            <Card.Body>

                                                <div className='lastfourthcardsection'>
                                                    <h4 className='lastfourthcardheader'>
                                                        {
                                                            course?.course_title
                                                        }
                                                    </h4>
                                                    {
                                                        course?.total_chapters && course?.total_lessons ? (
                                                            <h5 className='lastfourthcardheader2'>
                                                                {course?.total_chapters} {course?.total_chapters < 2 ? "Chapter" : "Chapters"} | {course?.total_lessons} {course?.total_lessons < 2 ? "Lesson" : "Lessons"}
                                                            </h5>
                                                        ) : (
                                                            <h5 className='lastfourthcardheader2'>
                                                                48 Chapters | 238 Lessons
                                                            </h5>
                                                        )
                                                    }

                                                    {
                                                        allCourseDetails && allCourseDetails[i] && (
                                                            <div className='my-3' style={{
                                                                maxWidth:"90%"
                                                            }} >
                                                                <ProgressBar variant="success" now={allCourseDetails[i].totalLessonsCompleted} max={allCourseDetails[i].totalNumberOfLessons} />
                                                                <h6 className='lastfourthcardheader2 mt-2' style={{
                                                                    fontSize:"1rem"
                                                                }}>
                                                                {allCourseDetails[i].totalLessonsCompleted} Lessons completed out of {allCourseDetails[i].totalNumberOfLessons} Lessons
                                                            </h6>

                                                            </div>
                                                        )
                                                    }
                                                    <div className='lastfourthsection2'>
                                                        <button className='herodiv-becomememberbtn3'> View Course</button>
                                                        <span className='fourthsectionspan'>
                                                            Life Time Access
                                                        </span>

                                                    </div>

                                                </div>
                                            </Card.Body>

                                        </Card>
                                    </Link>



                                ))
                            }

                        </div>

                    </div>
                )
            }

        </div>
    )
}

export default SilvaCourseCards