import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { AuthContext } from "../../../../context/AllContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

function SaleModal(props) {
  const { isMobile, isTablet,userData } = useContext(AuthContext);
  const [isTokenPresent, setIsTokenPresent] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    const tokenFromState = location?.state?.token;

    if (tokenParam || tokenFromState) {
      setIsTokenPresent(true)
    }
  }, [location.search]);


  useEffect(() => {
    const modalCount = parseInt(Cookies.get("saleModal") || 0);

    if (!isTokenPresent) {
      if (modalCount < 3) {
        const timer = setTimeout(() => {
          props.handleShow();

          const newCount = modalCount + 1;
          Cookies.set("saleModal", newCount.toString(), { expires: 5 });
        }, 3000);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [isTokenPresent]);

  const handleClick = () => {
    if (location.pathname === "/store/course/combo-plan") {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('token', 'SUNNYMIND');
      window.location.search = urlParams;
      props.onHide();
    } else {
      navigation('/?token=SUNNYMIND')
      props.scrollTo()
      props.onHide();

    }
  }

  if(userData && userData.enrollment_status==="Enrolled"){
    return null
  }

  return (
    <div >
      <Modal
        show={props.show}
        size="xl"
        
        onHide={() => {
          props.onHide();
        }}
        keyboard={false}
        centered
        style={{ cursor: "pointer" }}
        className="salemodalcontainer"
      >
        <Modal.Body
          className="d-flex justify-content-center align-items-center flex-column p-0 m-0"
          style={{ position: "relative", borderRadius: "15px" }}
        >
          <div style={{ position: 'absolute', right: 0, zIndex: 1, top: 0 }} onClick={() => props.onHide()}>
            <AiOutlineClose color="white" />
          </div>
          <div style={{ position: 'relative' }} onClick={handleClick}>
            <img src={isMobile?"/img/bg/bghero/Image20240823121256.jpg":"/img/bg/bghero/Image20240823121256.jpg"} alt="" style={{
              width: '100%', height: '100%', objectFit: 'cover', padding: 0, margin: 0
              , borderRadius: "15px"

            }} />
          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SaleModal;
