import React, { useEffect, useState } from 'react'
import CustomerReviews from './CustomerReviews'
import Slider from 'react-slick';
import CustomModal from './CustomModal';
import { useMediaQuery } from 'react-responsive';
import HeadingNewLatest from '../../pages/AboutUs/HeadingNewLatest';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ReviewsContainer({ title = "What Others say about The Silva Method" }) {
  const [modalShow, setModalShow] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [data, setData] = useState(null);

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' })

  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });


  const viewAll=()=>{
  
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    centerMode: isMobile ? false : true,
    centerPadding: "12%",
    prevArrow: <BsArrowLeft color='#fff' />,
    nextArrow: <BsArrowRight color='#fff' />,
  };

  const getreviews = async () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", "40bb9d38c66e40a86678979286f4e2b5");
    myHeaders.append("Device", "Web");
    myHeaders.append("Language", "english");

    const formData = new FormData();
    formData.append("start_index", "0");
    formData.append("no_of_records", "1000");


    const country = localStorage.getItem("countryname");
    const currency = localStorage.getItem("currency")


    if (country) {
      myHeaders.append("country", country.toString())
    }

    if (currency) {
      myHeaders.append("currency", currency.toString())
    }
    const options = {
      method: 'POST',
      headers: myHeaders,
      body: formData
    }
    const res = await fetch(`https://silvamethod.com/manage/api/testimonialList`, options)
    const data = await res.json();
    //console.log(data.data.classes);
    setReviews(data.data);
  }

  useEffect(() => {
    getreviews()
  }, []);
  return (
    <div className='bg-slider px-2'>
      <div className="reviewscontainer">
        <div className='row justify-content-center'>
          {/* <h2 style={{ fontWeight: "bolder", textAlign: "center" }}>{title}</h2> */}
          {/* <HeadingNew title={title} small /> */}

          <HeadingNewLatest title={"What People"} colored={"Say"} white />
          {/* <HeadingNew title={"About The Silva Method"} small white /> */}
          <h4 className='text-center white-color mt-3'>
            About The Silva Method
          </h4>
          <div style={{ width: isDesktopOrLaptop ? "100%" : "95%" }} className='reviewslider'>
            <Slider {...settings}>
              {
                reviews.map((val, i) => (
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <CustomerReviews data={val} setModalShow={setModalShow} setData={setData} />
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
        <div className="text-center mt-3">
        <Link to={"/testimonials"}>
          <button className="styledbtn23 px-5">View all</button>
        </Link>
      </div>
      </div>
      <CustomModal
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}

export default ReviewsContainer