import React from 'react';
import SingleChapterAccordion from './SingleChapterAccordion';
import NewPaymentCard from './NewPaymentCard';
import HeadingNew from '../AboutUs/HeadingNew';
import NewPaymentCard2 from './NewPaymentCard2';

const CustomImageGalleryWithSidebar = ({courseData,isCurrentCoursePurchased}) => {
  return (
    <main className='py-4'>
      <header></header>
      <div>
        {/* Nested on purpose to test it */}
        <div className="custom-container">
          <section className="custom-images">
           
            <div className='mb-3'>
              <HeadingNew title={"Course Details"} start={true} />
            </div>
            <SingleChapterAccordion courseData={courseData} isCurrentCoursePurchased={isCurrentCoursePurchased} />
          </section>
        
              <aside className="custom-sidebar">
           
              <NewPaymentCard2  isCurrentCoursePurchased={isCurrentCoursePurchased} />
            </aside>
            
        </div>
      </div>
    </main>
  );
};

export default CustomImageGalleryWithSidebar;
